// @ts-nocheck
export const APP_ROUTES = Object.freeze({
  PROFILE: "/meu-perfil",
  MEDIA_LIBRARY: "/biblioteca-de-midias",
  MEDIA_GROUPS: "/grupo-de-midias",
  PLAYLIST: "/playlist",
  PLAYLIST_SCHEDULES: "/agendamento",
  LOGIN: "/login",
});

const profileRoute = {
  showOnMenu: false,
  path: APP_ROUTES.PROFILE,
  name: "Meu perfil",
  icon: "user",
};

const playlistSchedulesRoute = {
  showOnMenu: true,
  path: APP_ROUTES.PLAYLIST_SCHEDULES,
  name: "Agendamento",
  icon: "calendar-alt",
};

const REDIRECT_APP_ROUTES = [
  {
    redirect: true,
    from: "/perfil",
    to: APP_ROUTES.PROFILE,
  },
  {
    redirect: true,
    from: "/",
    to: APP_ROUTES.PLAYLIST,
  },
];

const APP_ROUTES_LIST = [
  profileRoute,
  {
    showOnMenu: true,
    path: APP_ROUTES.PLAYLIST,
    name: "Playlists",
    icon: "play",
  },
  {
    showOnMenu: true,
    path: APP_ROUTES.MEDIA_GROUPS,
    name: "Grupo de mídias",
    icon: "folder-open",
  },
  {
    showOnMenu: true,
    path: APP_ROUTES.MEDIA_LIBRARY,
    name: "Mídias",
    icon: "file",
  },
];

export const appRoutesForCurrentUser = (currentUser) => {
  let routes = APP_ROUTES_LIST;
  if (currentUser.isAdmin) {
    routes = [playlistSchedulesRoute, ...routes];
  }
  return [...routes, ...REDIRECT_APP_ROUTES];
};
