import axios from "axios";
import Logger from "simplyas-js-logger";
import { IMediaGroupModel } from "../Model/MediaGroupModel";
import PlaylistScheduleModel from "../Model/PlaylistScheduleModel";
import { IUserModel } from "../Model/UserModel";
import AuthStore from "../store/AuthStore/AuthStore";
import NotificationsStore from "../store/NotificationsStore/NotificationsStore";

import { isProductionServer, SERVER_URL, isLocalhost } from "../utils/EnvUtils";

enum API_METHOD {
  submitLogin = "submitLogin",
  requestUser = "requestUser",
  requestUsers = "requestUsers",
  submitUser = "submitUser",
  updateUser = "updateUser",
  requestContracts = "requestContracts",
  deleteUser = "deleteUser",
  submitCompany = "submitCompany",
  updateCompany = "updateCompany",
  requestCompanies = "requestCompanies",
  deleteCompany = "deleteCompany",
  submitFile = "submitFile",
  updateFile = "updateFile",
  requestFiles = "requestFiles",
  deleteFile = "deleteFile",
  submitMediaGroup = "submitMediaGroup",
  updateMediaGroup = "updateMediaGroup",
  deleteMediaGroup = "deleteMediaGroup",
  requestMediaGroups = "requestMediaGroups",
  duplicateMediaGroup = "duplicateMediaGroup",
  submitPlaylist = "submitPlaylist",
  updatePlaylist = "updatePlaylist",
  duplicatePlaylist = "duplicatePlaylist",
  requestPlaylists = "requestPlaylists",
  deletePlaylist = "deletePlaylist",
  submitPlaylistSchedule = "submitPlaylistSchedule",
  updatePlaylistSchedule = "updatePlaylistSchedule",
  requestPlaylistSchedules = "requestPlaylistSchedules",
  deletePlaylistSchedule = "deletePlaylistSchedule",
  reorderPlaylistSchedules = "reorderPlaylistSchedules",
}

class MobR2APIService {
  authStore: AuthStore;
  notificationsStore: NotificationsStore;

  setStores = (
    authStore: AuthStore,
    notificationsStore: NotificationsStore
  ) => {
    this.authStore = authStore;
    this.notificationsStore = notificationsStore;
  };

  getBaseUrl = () => {
    const protocol = isLocalhost ? "https" : "https";
    return `${protocol}://${SERVER_URL}/api`;
  };

  postPublicMethod = (method, data = {}) => {
    const publicApi = axios.create({
      baseURL: this.getBaseUrl(),
      responseType: "json",
      headers: {
        "Content-Type": "application/json",
      },
    });

    return new Promise((resolve, reject) => {
      publicApi
        .post(method, data)
        .then((response) => resolve(response))
        .catch((error) => {
          reject(this.errorHandlingCenter(method, error));
        });
    });
  };

  postPrivateMethod = (method, data) => {
    if (this.authStore.isAuthenticated) {
      const privateApi = axios.create({
        baseURL: this.getBaseUrl(),
        responseType: "json",
        headers: {
          "Content-Type": "application/json",
        },
        maxRedirects: 0,
      });

      return new Promise((resolve, reject) => {
        privateApi
          .post(method, data)
          .then((response) => resolve(response))
          .catch((error) => {
            reject(this.errorHandlingCenter(method, error));
          });
      });
    }

    return Promise.reject(null);
  };

  saveErrorLog = (logData: any) => {
    Logger.error("MobR2 API Error", logData);
  };

  errorHandlingCenter = (method, error) => {
    const logData = {
      method,
      originError: JSON.stringify(error),
    };

    if (error.response && error.response.status) {
      const errorStatusCode = error.response.status;
      const data = error.response.data || {};

      const responseError = {
        statusCode: errorStatusCode,
        ...data,
        message:
          (error.response.data && error.response.data.error) ||
          "Erro desconhecido",
      };

      if (errorStatusCode === 502) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "502 error caused logout",
        });
        this.notificationsStore.addServerErrorNotification();
        if (isProductionServer) this.authStore.logout();
        return null;
      }

      if (errorStatusCode === 403) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "403 error caused logout",
        });
        // FORBIDDEN / ACCESS DENIED
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        if (isProductionServer) this.authStore.logout();
        return null;
      } else if (errorStatusCode === 409) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "409 error",
        });
        // CONFLICT
        return responseError;
      } else if (errorStatusCode === 429) {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "429 error",
        });
        // API is busy
        return responseError;
      } else {
        this.saveErrorLog({
          ...logData,
          responseError,
          comment: "Generic error",
        });
        this.notificationsStore.addSnackbarNotification({
          message: responseError.message,
          timeout: 10000,
          color: "danger",
        });
        return responseError;
      }
    } else {
      this.saveErrorLog({
        ...logData,
        comment: "Fatal error did logout",
      });
      this.notificationsStore.addServerErrorNotification();
      if (isProductionServer) this.authStore.logout();
      return null;
    }
  };

  getDefaultParams = () => {
    if (this.authStore.isAuthenticated) {
      return {
        user_token: this.authStore.currentUserToken,
      };
    }
    return {};
  };

  getExtraParams = () => {
    return {};
  };

  sanitizeFileType = (type) => {
    return type
      .replace("image/", "")
      .replace("audio/", "")
      .replace("video/", "")
      .replace("jpeg", "jpg");
  };

  // Auth
  submitLogin = ({ email, password }): Promise<any> => {
    return this.postPublicMethod(API_METHOD.submitLogin, {
      email,
      password,
    });
  };

  requestUser = async (userToken) => {
    return this.postPublicMethod(API_METHOD.requestUser, {
      user_token: userToken,
    });
  };

  requestUsers = () => {
    return this.postPrivateMethod(
      API_METHOD.requestUsers,
      this.getDefaultParams()
    );
  };

  sanitizeUserData = (
    { name, email, isAdmin, isActive, permissions }: IUserModel,
    password?: string
  ) => ({
    ...this.getDefaultParams(),
    name,
    email,
    password: password && password.length > 1 ? password : undefined,
    is_admin: isAdmin,
    is_active: isActive,
    permissions,
  });

  submitUser = (data: IUserModel, password: string) => {
    return this.postPrivateMethod(
      API_METHOD.submitUser,
      this.sanitizeUserData(data, password)
    );
  };
  updateUser = (data: IUserModel) => {
    return this.postPrivateMethod(API_METHOD.updateUser, {
      ...this.sanitizeUserData(data),
      token_user: data.token,
    });
  };
  deleteUser = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deleteUser, {
      ...this.getDefaultParams(),
      token_user: token,
    });
  };

  // Contracts
  requestContracts = () => {
    return this.postPublicMethod(API_METHOD.requestContracts, {
      ...this.getDefaultParams(),
    });
  };

  recoverPassword = ({ email }) => {
    return this.postPublicMethod(API_METHOD.submitLogin, {
      email,
      recover: true,
    });
  };

  // Company
  requestCompanies = () => {
    return this.postPrivateMethod(
      API_METHOD.requestCompanies,
      this.getDefaultParams()
    );
  };
  submitCompany = (name: string, serverUrl: string) => {
    return this.postPrivateMethod(API_METHOD.submitCompany, {
      ...this.getDefaultParams(),
      name,
      server_url: serverUrl,
    });
  };
  updateCompany = (token: string, name: string, serverUrl: string) => {
    return this.postPrivateMethod(API_METHOD.updateCompany, {
      ...this.getDefaultParams(),
      token,
      name,
      server_url: serverUrl,
    });
  };
  deleteCompany = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deleteCompany, {
      ...this.getDefaultParams(),
      token,
    });
  };

  // Files
  requestFiles = () => {
    return this.postPrivateMethod(
      API_METHOD.requestFiles,
      this.getDefaultParams()
    );
  };
  requestFile = (token: string) => {
    return this.postPrivateMethod(API_METHOD.requestFiles, {
      ...this.getDefaultParams(),
      token,
    });
  };

  sanitizeFileData = ({ name, duration, videoFile }) => ({
    ...this.getDefaultParams(),
    name,
    duration,
    media_type: videoFile ? this.sanitizeFileType(videoFile.type) : undefined,
    media_data: videoFile ? videoFile.base64 : undefined,
  });

  submitFile = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitFile,
      this.sanitizeFileData(data)
    );
  };

  updateFile = (data) => {
    return this.postPrivateMethod(API_METHOD.updateFile, {
      ...this.sanitizeFileData(data),
      token: data.token,
    });
  };

  deleteFile = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deleteFile, {
      ...this.getDefaultParams(),
      token,
    });
  };

  // Media Groups
  requestMediaGroups = () => {
    return this.postPrivateMethod(
      API_METHOD.requestMediaGroups,
      this.getDefaultParams()
    );
  };
  requestMediaGroup = (token: string) => {
    return this.postPrivateMethod(API_METHOD.requestMediaGroups, {
      ...this.getDefaultParams(),
      token,
    });
  };
  sanitizeMediaGroupData = ({
    name,
    isRandom,
    isActive,
    category,
    mediasList,
  }: IMediaGroupModel) => ({
    ...this.getDefaultParams(),
    name,
    is_random: isRandom,
    is_active: isActive,
    category,
    media_files: mediasList,
  });

  submitMediaGroup = (data: IMediaGroupModel) => {
    return this.postPrivateMethod(
      API_METHOD.submitMediaGroup,
      this.sanitizeMediaGroupData(data)
    );
  };
  updateMediaGroup = (data: IMediaGroupModel) => {
    return this.postPrivateMethod(API_METHOD.updateMediaGroup, {
      ...this.sanitizeMediaGroupData(data),
      token: data.token,
    });
  };
  duplicateMediaGroup = ({ name, token }: { name: string; token: string }) => {
    return this.postPrivateMethod(API_METHOD.duplicateMediaGroup, {
      ...this.getDefaultParams(),
      name,
      token,
    });
  };
  deleteMediaGroup = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deleteMediaGroup, {
      ...this.getDefaultParams(),
      token,
    });
  };

  // Playlists
  requestPlaylists = () => {
    return this.postPrivateMethod(
      API_METHOD.requestPlaylists,
      this.getDefaultParams()
    );
  };

  sanitizePlaylistData = ({ name, isRandom, isMuted, mediaGroupsList }) => {
    return {
      ...this.getDefaultParams(),
      name: name.trim(),
      is_random: isRandom,
      is_muted: isMuted,
      media_groups: mediaGroupsList.map(($0) => $0.token),
    };
  };

  submitPlaylist = (data) => {
    return this.postPrivateMethod(
      API_METHOD.submitPlaylist,
      this.sanitizePlaylistData(data)
    );
  };

  updatePlaylist = (data) => {
    return this.postPrivateMethod(API_METHOD.updatePlaylist, {
      ...this.sanitizePlaylistData(data),
      token: data.token,
    });
  };

  duplicatePlaylist = ({ name, token }: { name: string; token: string }) => {
    return this.postPrivateMethod(API_METHOD.duplicatePlaylist, {
      ...this.getDefaultParams(),
      name: name.trim(),
      token,
    });
  };

  deletePlaylist = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deletePlaylist, {
      ...this.getDefaultParams(),
      token,
    });
  };

  // Playlists Schedules
  requestPlaylistSchedules = () => {
    return this.postPrivateMethod(
      API_METHOD.requestPlaylistSchedules,
      this.getDefaultParams()
    );
  };

  sanitizePlaylistSchedulesData = (playlistSchedule: PlaylistScheduleModel) => {
    return {
      ...this.getDefaultParams(),
      contract_hash: playlistSchedule.companyToken,
      playlist_token: playlistSchedule.playlistToken,
      schedule: [playlistSchedule.schedule],
      is_active: playlistSchedule.isActive,
    };
  };

  submitPlaylistSchedule = (data: PlaylistScheduleModel) => {
    return this.postPrivateMethod(
      API_METHOD.submitPlaylistSchedule,
      this.sanitizePlaylistSchedulesData(data)
    );
  };

  updatePlaylistSchedule = (data: PlaylistScheduleModel) => {
    return this.postPrivateMethod(API_METHOD.updatePlaylistSchedule, {
      ...this.sanitizePlaylistSchedulesData(data),
      token: data.token,
    });
  };

  deletePlaylistSchedule = (token: string) => {
    return this.postPrivateMethod(API_METHOD.deletePlaylistSchedule, {
      ...this.getDefaultParams(),
      token,
    });
  };

  reorderPlaylistSchedules = (orderedList: string[]) => {
    return this.postPrivateMethod(API_METHOD.reorderPlaylistSchedules, {
      ...this.getDefaultParams(),
      ordered_list: orderedList,
    });
  };
}

export default MobR2APIService;
