import { observable, action, computed } from "mobx";

// Model
import ContractModel from "../../Model/ContractModel";

import MobR2APIService from "../../services/MobR2APIService";
import { didSearchList } from "../../utils/Utils";

class ContractStore {
  mobr2APIService: MobR2APIService;

  @observable contractsList: ContractModel[] = [];
  @observable isFetching = false;
  @observable searchString = "";

  constructor(mobr2APIService: MobR2APIService) {
    this.mobr2APIService = mobr2APIService;
  }

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setContractsList = (newValue: ContractModel[]) => {
    this.contractsList = newValue;
  };

  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };

  @computed
  get filteredContractsList(): ContractModel[] {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.contractsList,
        ({ name }: ContractModel, textIncludesSearchString) =>
          textIncludesSearchString(name)
      );
    }
    return this.contractsList;
  }

  getContractsList = async () => {
    this.setIsFetching(true);
    let newContractsList: ContractModel[] = [];

    try {
      const response: any = await this.mobr2APIService.requestContracts();

      if (response.status === 200) {
        response.data.forEach((contractData) => {
          const newContract = new ContractModel(contractData);
          newContractsList.push(newContract);
        });

        this.setContractsList(newContractsList);
        this.setIsFetching(false);
      }
    } catch (error) {
      console.error("Error in getContractsList", error);
    }
  };

  getWithToken = (token: string): ContractModel | null => {
    return this.contractsList.find(({ hash }) => token === hash) || null;
  };

  clearStore = () => {
    this.setIsFetching(false);
    this.setContractsList([]);
  };
}

export default ContractStore;
