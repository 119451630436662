export enum EInternalEvents {
  didLogout = "didLogout",
  mediaWasDeleted = "mediaWasDeleted",
  mediaGroupWasDeleted = "mediaGroupWasDeleted",
  triggeredAction = "triggeredAction",
}

export interface InternalEventSubscriber {
  observer: any;
  callback: (params?: any) => void | Promise<any>;
}

class InternalEventsStore {
  subscribers: { [key: string]: Array<InternalEventSubscriber> } = {};

  subscribeTo = ({
    eventKey,
    observer,
    callback,
  }: {
    eventKey: EInternalEvents;
    observer: any;
    callback: (params?: any) => void | Promise<any>;
  }) => {
    if (!this.subscribers[eventKey]) {
      this.subscribers[eventKey] = [];
    }

    this.subscribers[eventKey].push({
      observer,
      callback,
    });
  };

  unsubscribe = (observerToRemove: any, eventKey: EInternalEvents) => {
    if (this.subscribers[eventKey]) {
      this.subscribers[eventKey] = this.subscribers[eventKey].filter(
        ({ observer }) => observer !== observerToRemove
      );
    }
  };

  notify = (
    eventKey: EInternalEvents,
    params?: any,
    notifierCallback?: (result: any) => void
  ) => {
    if (this.subscribers[eventKey]) {
      this.subscribers[eventKey].forEach(({ callback }) => {
        const result = callback(params);
        if (result && notifierCallback) {
          notifierCallback(result);
        }
      });
    }
  };
}

export default InternalEventsStore;
