// @ts-nocheck
import { APP_ROUTES } from "./app-routes";

// Pages
import MediaLibraryPage from "../pages/MediaLibraryPage";
import MediaGroupsPage from "../pages/MediaGroupsPage";
import PlaylistsPage from "../pages/PlaylistsPage";
import PlaylistSchedulesPage from "../pages/PlaylistSchedulesPage";

export const getPageComponentForRoutePath = (routePath) => {
  switch (routePath) {
    case APP_ROUTES.PROFILE:
      return PlaylistsPage;
    case APP_ROUTES.MEDIA_LIBRARY:
      return MediaLibraryPage;
    case APP_ROUTES.MEDIA_GROUPS:
      return MediaGroupsPage;
    case APP_ROUTES.PLAYLIST:
      return PlaylistsPage;
    case APP_ROUTES.PLAYLIST_SCHEDULES:
      return PlaylistSchedulesPage;

    default:
      return null;
  }
};
