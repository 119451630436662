import React, { Component } from "react";
import _debounce from "lodash/debounce";

import ScheduleRuleForCompanyItem from "./ScheduleRuleForCompanyItem";
import PlaylistScheduleModel from "../../Model/PlaylistScheduleModel";
import Badge from "../../components/Badge/Badge";
import DragAndDropContainer from "../../components/DragAndDrop";

interface ScheduleRuleForCompanyProps {
  companyName: string;
  companyToken: string;
  getPlaylistName: (token: string) => string;
  playlistSchedulesList: PlaylistScheduleModel[];
  didWantToEditSchedule: (token: string) => void;
  didReorderSchedules: (orderedList: string[]) => void;
}

interface ScheduleRuleForCompanyState {
  itemsList: PlaylistScheduleModel[];
}

class ScheduleRuleForCompany extends Component<
  ScheduleRuleForCompanyProps,
  ScheduleRuleForCompanyState
> {
  constructor(props: ScheduleRuleForCompanyProps) {
    super(props);

    this.state = {
      itemsList: props.playlistSchedulesList,
    };
  }

  componentDidUpdate(
    prevProps: ScheduleRuleForCompanyProps,
    prevState: ScheduleRuleForCompanyState
  ) {
    const left = JSON.stringify(prevProps.playlistSchedulesList);
    const right = JSON.stringify(this.props.playlistSchedulesList);

    if (left !== right) {
      this.setState({
        itemsList: this.props.playlistSchedulesList,
      });
    }
  }

  componentWillUnmount() {
    this.applyReorder.flush();
  }

  applyReorder = _debounce((data: PlaylistScheduleModel[]) => {
    this.props.didReorderSchedules(data.map(({ token }) => token));
  }, 4000);

  didReorderList = (reorderedList: PlaylistScheduleModel[]) => {
    this.setState(
      {
        itemsList: reorderedList,
      },
      () => this.applyReorder(reorderedList)
    );
  };

  render() {
    const {
      companyName,
      companyToken,
      getPlaylistName,
      didWantToEditSchedule,
    } = this.props;
    const { itemsList } = this.state;

    if (itemsList.length < 1) {
      return null;
    }

    const dragNDropIsActive = itemsList.length > 1;

    return (
      <DragAndDropContainer<PlaylistScheduleModel>
        contextId={`playlistSchedulesDND_${companyToken}`}
        didReorderList={this.didReorderList}
        itemsList={itemsList}
        renderContainer={() => (
          <Badge
            color="primary"
            style={{ fontSize: "1.1em", marginBottom: 10 }}
          >
            {companyName}
          </Badge>
        )}
        renderItem={(
          { token, playlistToken, isActive, schedule }: PlaylistScheduleModel,
          index: number,
          isDragging: boolean
        ) => (
          <ScheduleRuleForCompanyItem
            key={token}
            token={token}
            label={getPlaylistName(playlistToken)}
            isActive={isActive}
            schedule={schedule}
            canDrag={dragNDropIsActive}
            isDragging={isDragging}
            didWantToEditSchedule={didWantToEditSchedule}
          />
        )}
      />
    );
  }
}

export default ScheduleRuleForCompany;
