import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";
import Select from "react-select";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { GridContainer, GridItem } from "../../components/Grid";
import LoadingView from "../../components/LoadingView/LoadingView";
import { Button } from "../../components/Button";
import Modal from "../../components/Modal/Modal";
import ConfirmDeleteModal from "../../components/Modal/ConfirmDeleteModal";
import ScheduleText from "../../components/ScheduleText/ScheduleText";
import ScheduleForm from "../../components/AppForm/ScheduleForm";

// Icon
import { SaveIcon, DeleteIcon } from "../../components/Icon";

import PlaylistScheduleStore from "../../store/PlaylistScheduleStore/PlaylistScheduleStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";
import PlaylistModel from "../../Model/PlaylistModel";
import PlaylistScheduleModel, {
  defaultAlwaysOnSchedule,
  ScheduleInterface,
} from "../../Model/PlaylistScheduleModel";
import ContractStore from "../../store/ContractStore/ContractStore";
import ContractModel from "../../Model/ContractModel";

// Styles
const styles = createStyles({
  sectionTitle: {
    fontSize: "1.4em",
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
    marginBottom: 0,
  },
});

interface ScheduleWhatWhereWhenProps {
  classes?: any;
  playlistSchedule: PlaylistScheduleModel;
  what: PlaylistModel;
  whatList: PlaylistModel[];
  where: ContractModel;
  whereList: ContractModel[];
  when: ScheduleInterface;
  isActive: boolean;
  didSubmit: () => void;
  didDelete: () => void;
}

interface ScheduleWhatWhereWhenState {
  what: PlaylistModel;
  where: ContractModel;
  when: ScheduleInterface;
  isActive: boolean;
  isOpenModal: boolean;
  didWantToDelete: boolean;
}

interface InjectedProps extends ScheduleWhatWhereWhenProps {
  playlistScheduleStore: PlaylistScheduleStore;
  notificationsStore: NotificationsStore;
  contractStore: ContractStore;
}

@inject("playlistScheduleStore", "notificationsStore", "contractStore")
@observer
class ScheduleWhatWhereWhen extends Component<
  ScheduleWhatWhereWhenProps,
  ScheduleWhatWhereWhenState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  constructor(props: ScheduleWhatWhereWhenProps) {
    super(props);

    this.state = {
      what: this.getWhatFromProps(props),
      where: props.where ? props.where : null,
      when: props.when ? props.when : null,
      isActive: props.isActive,
      isOpenModal: false,
      didWantToDelete: false,
    };
  }

  componentDidMount() {
    const { contractStore } = this.injected;
    if (contractStore.contractsList.length < 1) {
      contractStore.getContractsList();
    }
  }

  getWhatFromProps = (props) => {
    if (props.what) return props.what;
    if (props.whatList.length === 1) {
      return props.whatList[0];
    }
    return null;
  };

  prepareUpdateData = (isActive: boolean): PlaylistScheduleModel => {
    const { what, where, when } = this.state;
    return new PlaylistScheduleModel({
      ...this.props.playlistSchedule,
      playlistToken: what.token,
      companyToken: where.hash,
      schedule: when,
      isActive,
    });
  };

  toggleIsActive = () => {
    const data = this.prepareUpdateData(!this.props.isActive);
    this.onUpdate(data);
  };

  didSelectWhat = (selected) =>
    this.setState({
      what: selected,
    });

  didSelectWhere = (selected) =>
    this.setState({
      where: selected,
    });

  didChangeWhen = (changedResource) => {
    this.setState({
      when: changedResource,
    });
  };

  didCancelConfiguringWhen = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  didWantToConfigureWhen = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didConfirmDeleteAction = () => {
    this.injected.playlistScheduleStore.deletePlaylistSchedule(
      this.props.playlistSchedule.token
    );
    this.props.didDelete();
  };

  onUpdate = (data: PlaylistScheduleModel) => {
    this.injected.playlistScheduleStore
      .updatePlaylistSchedule(data)
      .then(this.didSubmit);
  };

  onSubmit = (event: MouseEvent | TouchEvent) => {
    if (event) {
      event.preventDefault();
    }
    const { what, where } = this.state;
    if (!what) {
      this.injected.notificationsStore.addSnackbarNotification({
        message: "Selecione a Playlist",
        color: "danger",
      });
      return;
    }

    if (!where) {
      this.injected.notificationsStore.addSnackbarNotification({
        message: "Selecione uma Empresa",
        color: "danger",
      });
      return;
    }

    if (this.props.playlistSchedule) {
      this.onUpdate(this.prepareUpdateData(this.props.isActive));
    } else {
      this.injected.playlistScheduleStore
        .submitPlaylistSchedule(
          new PlaylistScheduleModel({
            playlistToken: what.token,
            companyToken: where.hash,
            schedule: this.state.when || defaultAlwaysOnSchedule,
            isActive: this.props.isActive,
          })
        )
        .then(this.didSubmit);
    }
  };

  didSubmit = () => {
    if (this.props.didSubmit) {
      this.props.didSubmit();
    }
  };

  render() {
    const { classes, isActive } = this.props;
    const { isOpenModal, didWantToDelete } = this.state;
    const isEditing = this.props.playlistSchedule;
    const { contractStore } = this.injected;

    return (
      <GridContainer>
        {isOpenModal && (
          <Modal
            isOpen={isOpenModal}
            didCloseModal={this.didCancelConfiguringWhen}
            contentOverflowVisible
          >
            <ScheduleForm
              schedule={this.state.when || defaultAlwaysOnSchedule}
              saveSchedule={this.didChangeWhen}
            />
          </Modal>
        )}
        {didWantToDelete && (
          <ConfirmDeleteModal
            didDelete={this.didConfirmDeleteAction}
            didCancel={this.didCancelDeleteAction}
            message="Você realmente quer apagar esta regra?"
          />
        )}

        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>Playlist</h2>
          <Select
            value={this.state.what}
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            placeholder="selecione..."
            options={this.props.whatList}
            noOptionsMessage={() => "Nada encontrado"}
            onChange={this.didSelectWhat}
            isClearable={false}
            getOptionValue={(option) => option.token}
          />
        </GridItem>
        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>Empresa</h2>
          {contractStore.isFetching ? (
            <LoadingView height={30} />
          ) : (
            <Select
              value={this.state.where}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              menuPortalTarget={document.body}
              placeholder="selecione..."
              options={contractStore.contractsList}
              noOptionsMessage={() => "Nada encontrado"}
              onChange={this.didSelectWhere}
              isClearable={false}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.hash}
            />
          )}
        </GridItem>
        <GridItem xs={12} md={4}>
          <h2 className={classes.sectionTitle}>Quando</h2>
          {this.state.when ? (
            <Button
              small
              block
              color="success"
              outline
              onClick={this.didWantToConfigureWhen}
            >
              <ScheduleText schedule={this.state.when} />
            </Button>
          ) : (
            <Button
              small
              block
              color="info"
              onClick={this.didWantToConfigureWhen}
            >
              Configurar <i className="fas fa-cog" />
            </Button>
          )}
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <GridContainer justify="space-between">
            {isEditing && (
              <Fragment>
                <GridItem xs={12} sm={4}>
                  <Button color="danger" block onClick={this.didWantToDelete}>
                    Apagar <DeleteIcon />
                  </Button>
                </GridItem>
                <GridItem xs={12} sm={4}>
                  <Button
                    color={isActive ? "warning" : "success"}
                    block
                    onClick={this.toggleIsActive}
                  >
                    {isActive ? (
                      <span>
                        Inativar regra <i className="fas fa-calendar-times" />
                      </span>
                    ) : (
                      <span>
                        Habilitar regra <i className="fas fa-calendar-check" />
                      </span>
                    )}
                  </Button>
                </GridItem>
              </Fragment>
            )}
            <GridItem xs sm>
              <Button block color="primary" onClick={this.onSubmit}>
                {isEditing ? "Atualizar" : "Salvar"} <SaveIcon />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(ScheduleWhatWhereWhen);
