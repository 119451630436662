// @ts-nocheck
import React from "react";
import Lottie from "react-lottie";

const LottieAnimation = ({ animationData, height }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div>
      <Lottie options={defaultOptions} height={height} width={height} />
    </div>
  );
};

LottieAnimation.defaultProps = {
  height: 200
};

export default LottieAnimation;
