import React from "react";
import ConfirmModal from "./ConfirmModal";

import InfoLabel from "../InfoLabel/InfoLabel";

interface IConfirmDeleteModalProps {
  deleteLabel?: string | React.ReactElement;
  message?: string | React.ReactElement;
  didDelete: () => void;
  didCancel: () => void;
}

const ConfirmDeleteModal: React.FunctionComponent<IConfirmDeleteModalProps> = ({
  deleteLabel,
  message,
  didDelete,
  didCancel,
}) => (
  <ConfirmModal
    isOpen
    title="Confirme esta ação"
    didConfirm={didDelete}
    didCancel={didCancel}
    confirmLabel={deleteLabel || "Apagar"}
    cancelLabel="Cancelar"
    cancelButtonColor="default"
    confirmButtonColor="danger"
    confirmLeft
  >
    <InfoLabel style={{ margin: "16px 0" }}>
      {message ? message : "Esta ação não poderá ser desfeita."}
    </InfoLabel>
  </ConfirmModal>
);

export default ConfirmDeleteModal;
