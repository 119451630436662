// @ts-nocheck
import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import LoginLayout from "../layouts/LoginLayout";
import BaseLayout from "../layouts/BaseLayout";

import { GridFullScreen } from "../components/Grid";
import LoadingView from "../components/LoadingView/LoadingView";

import { APP_ROUTES } from "./app-routes";

@inject("authStore")
class Logout extends Component {
  componentDidMount() {
    const { force = false } = this.props;
    this.props.authStore.logout(force);
  }
  render() {
    return <LoadingView fullPageCenter />;
  }
}

@inject("authStore")
@observer
class AppRouter extends Component {
  state = {
    isMaintenanceMode: false,
    versionIsUpToDate: true,
    isUpdatingVersion: false,
  };

  render() {
    // Logout route
    if (window.location.href.includes("/logout-force")) {
      return <Logout force />;
    }

    const { authStore } = this.props;

    if (authStore.isFetching) {
      return (
        <GridFullScreen>
          <LoadingView />
        </GridFullScreen>
      );
    }

    const { isAuthenticated } = authStore;

    return (
      <>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <PrivateRoute
              exact
              isAuthenticated={isAuthenticated}
              path="/logout"
              component={Logout}
            />
            <PublicRoute
              exact
              path={APP_ROUTES.LOGIN}
              isAuthenticated={isAuthenticated}
              component={LoginLayout}
            />
            <PrivateRoute
              path="/"
              isAuthenticated={isAuthenticated}
              component={BaseLayout}
            />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default AppRouter;
