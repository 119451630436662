import React from "react";

// Utils
import { getActiveDaysFromArray } from "../../utils/DateUtils";

const DaysWeekString: React.FunctionComponent<{ daysWeek: string[] }> = ({
  daysWeek,
}) => {
  const { sun, mon, tue, wed, thu, fri, sat } = getActiveDaysFromArray(
    daysWeek
  );

  let resultString = "";

  if (sun && sat && !mon && !tue && !wed && !thu && !fri) {
    resultString = "Finais de semana";
  } else if (mon && tue && wed && thu && fri && !sun && !sat) {
    resultString = "Dias da Semana";
  } else if (sun && mon && tue && wed && thu && fri && sat) {
    resultString = "Todos os dias";
  } else if (!sun && !mon && !tue && !wed && !thu && !fri && !sat) {
    resultString = "Nunca";
  } else {
    resultString = daysWeek.join(", ");
  }

  return (
    <span>
      <i className="fas fa-calendar-check" /> {resultString}
    </span>
  );
};

export default DaysWeekString;
