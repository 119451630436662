import SettingsStore from "../store/SettingsStore/SettingsStore";

class FileService {
  settingsStore: SettingsStore;

  setStores = (settingsStore: SettingsStore) => {
    this.settingsStore = settingsStore;
  };

  get serverUrl() {
    if (this.settingsStore) {
      return this.settingsStore.mediaFilesPath;
    }
    return null;
  }

  mountFileUrl = (hash: string) => {
    if (hash.length < 1) {
      return "";
    }
    if (this.serverUrl) {
      return `${this.serverUrl}/${hash}`;
    }
    return "";
  };
}

export default FileService;
