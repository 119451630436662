import { observable, computed, action } from "mobx";
import Logger from "simplyas-js-logger";

// Model
import PlaylistModel from "../../Model/PlaylistModel";

import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import MobR2APIService from "../../services/MobR2APIService";
import FileService from "../../services/FileService";

// Util
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";
import { didSearchList } from "../../utils/Utils";

class PlaylistsStore {
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable
  dataList: PlaylistModel[] = [];
  @observable
  isFetching: boolean = false;
  @observable
  searchString: string = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.searchString = "";
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };
  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setDataList = (newList: PlaylistModel[]) => {
    this.dataList = newList;
  };

  @computed
  get filteredDataList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (playlist: PlaylistModel, textIncludesSearchString) =>
          textIncludesSearchString(playlist.label)
      );
    }

    return this.dataList;
  }

  getDataList = async () => {
    this.setIsFetching(true);

    try {
      let newPlaylistsList = [];
      const response: any = await this.mobR2APIService.requestPlaylists();
      if (response.status === 200) {
        response.data.forEach((playlistData: any) => {
          const newPlaylist = new PlaylistModel(playlistData);
          newPlaylistsList.push(newPlaylist);
        });
      }
      this.setDataList(newPlaylistsList.sort(dynamicSortByProperty("name")));
    } catch (error) {
      Logger.error("error in requestPlaylists", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  submit = async (playlist: PlaylistModel) => {
    try {
      const response: any = await this.mobR2APIService.submitPlaylist(playlist);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      await this.getDataList();
    } catch (error) {
      Logger.error("error submitting Playlist", error);
    }
  };

  update = async (playlist: PlaylistModel) => {
    try {
      const response: any = await this.mobR2APIService.updatePlaylist(playlist);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getDataList();
    } catch (error) {
      Logger.error("error updating Playlist", error);
    }
  };

  duplicate = async (token) => {
    this.setIsFetching(true);
    const playlist = this.getWithToken(token);
    if (!playlist) return;
    try {
      const response: any = await this.mobR2APIService.duplicatePlaylist({
        token,
        name: `${playlist.name} - Cópia`,
      });
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
      });
      this.getDataList();
    } catch (error) {
      Logger.error("error in duplicatePlaylist", error);
      this.setIsFetching(false);
    }
  };

  delete = async (token: string) => {
    this.setIsFetching(true);
    try {
      const response: any = await this.mobR2APIService.deletePlaylist(token);
      this.getDataList();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "warning",
      });
    } finally {
      this.setIsFetching(false);
    }
  };

  getWithToken = (token: string): PlaylistModel | null => {
    if (this.dataList.length > 0) {
      return this.dataList.find((playlist) => playlist.token === token);
    }
    return null;
  };
}

export default PlaylistsStore;
