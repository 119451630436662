// @ts-nocheck
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";

import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import AlertModal from "../components/AlertModal/AlertModal";
import { SimpleCard } from "../components/Card";
import MediaGroupForm from "../components/AppForm/MediaGroupForm";
import { GridContainer, GridItem } from "../components/Grid";

import { LinkButton } from "../components/Button";

import MediaGroupsTableContainer from "./mediaGroupsPage/MediaGroupsTableContainer";

import MediaGroupModel from "../Model/MediaGroupModel";

import { APP_ROUTES } from "../routes/app-routes";

@inject("mediaLibraryStore")
@observer
class MediaGroupsPage extends Component {
  state = {
    selectedMediaGroup: null,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.mediaLibraryStore.requestMediaGroups();
  }

  getTableData = (mediaGroupsList: MediaGroupModel[]) => {
    return mediaGroupsList.map(
      ({ token, name, category, mediasList, isRandom, isActive }) => ({
        name: name,
        category: category,
        files: mediasList.length,
        isRandom: <BooleanIcon isTrue={isRandom} />,
        isActive: <BooleanIcon isTrue={isActive} />,
        actions: token,
      })
    );
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDelete = () => {
    this.props.mediaLibraryStore
      .deleteMediaGroup(this.state.selectedMediaGroup.token)
      .then(() => {
        this.setState({
          didWantToDelete: false,
          selectedMediaGroup: null,
        });
      });
  };

  didWantToDeleteRow = (token: string) => async () => {
    const selectedMediaGroup = await this.props.mediaLibraryStore.requestMediaGroup(
      token
    );

    if (selectedMediaGroup) {
      this.setState({
        didWantToDelete: true,
        selectedMediaGroup,
      });
    }
  };

  didWantToCloneRow = (token: string) => () => {
    this.props.mediaLibraryStore.duplicateMediaGroup(token);
  };

  didWantToEditRow = (token: string) => () => {
    const { history, match } = this.props;
    history.push(`${match.path}/editar/${token}`);
  };

  didWantToAdd = () => {
    const { history, match } = this.props;
    history.push(`${match.path}/adicionar`);
  };

  renderForm = (props) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10} lg={8}>
          <SimpleCard cardStyle={{ marginBottom: 240 }}>
            <MediaGroupForm {...props} />
          </SimpleCard>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { mediaLibraryStore } = this.props;
    const {
      filteredMediaGroupsList: mediaGroupsList,
      dataList,
      searchString,
      setSearchString,
      isFetchingMediaGroups: isFetching,
    } = mediaLibraryStore;
    const { didWantToDelete, selectedMediaGroup } = this.state;

    const pathUrl = this.props.match.path;

    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Grupo de mídias</PageTitle>

        {didWantToDelete && (
          <AlertModal
            warning
            title="Confirmar esta ação"
            onConfirm={this.didDelete}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel="Apagar"
            cancelButtonLabel="Cancelar"
            showCancel
          >
            {selectedMediaGroup.usedInPlaylists.length > 0 && (
              <div style={{ textAlign: "left" }}>
                <p style={{ margin: 0 }}>
                  Este grupo de mídias está sendo utilizado na(s) seguinte(s)
                  playlist(s):
                </p>
                <ul style={{ marginTop: 0 }}>
                  {selectedMediaGroup.usedInPlaylists.map(({ name, token }) => (
                    <li key={token} style={{ listStyleType: "none" }}>
                      <LinkButton
                        color="transparent"
                        small
                        style={{ margin: 0, padding: "4px 0" }}
                        to={`${APP_ROUTES.PLAYLIST}/editar/${token}`}
                        target="_blank"
                      >
                        <i className="far fa-hand-point-right" /> {name}
                      </LinkButton>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <i className="fas fa-exclamation-triangle" /> Esta ação não poderá
            ser desfeita.
          </AlertModal>
        )}
        <Switch>
          <Route
            path={`${pathUrl}/adicionar`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={`${pathUrl}/editar/:mediaGroupToken`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={pathUrl}
            render={(props) => (
              <MediaGroupsTableContainer
                {...props}
                dataList={dataList}
                searchString={searchString}
                setSearchString={setSearchString}
                data={this.getTableData(mediaGroupsList)}
                isFetching={isFetching}
                didWantToAdd={this.didWantToAdd}
                didWantToDeleteRow={this.didWantToDeleteRow}
                didWantToCloneRow={this.didWantToCloneRow}
                didWantToEditRow={this.didWantToEditRow}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default MediaGroupsPage;
