// @ts-nocheck
import React from "react";
import { Switch, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";

import LoginPage from "../pages/LoginPage";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Style
import pagesStyle from "../assets/jss/js-styles/layouts/pagesStyle";

// Utils

const LoginLayout = inject("settingsStore")(
  observer(({ settingsStore, classes }) => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <Switch>
            <Route path={"/login"} component={LoginPage} />
          </Switch>
        </div>
        <div
          className={classes.fullPageBackground}
          style={{
            backgroundImage: `url("${settingsStore.loginPageBgImage}")`,
          }}
        />
      </div>
    );
  })
);

export default withStyles(pagesStyle)(LoginLayout);
