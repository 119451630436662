import PlaylistModel from "./PlaylistModel";
import MediaModel from "./MediaModel";

export enum EMediaGroupCategory {
  channel = "channel",
  program = "program",
}

export interface IMediaGroupModel {
  token?: string;
  name: string;
  isRandom?: boolean;
  isActive?: boolean;
  category?: EMediaGroupCategory;
  mediasList?: MediaModel[];
  usedInPlaylists?: PlaylistModel[];
  durationTotal?: number;
}

class MediaGroupModel implements IMediaGroupModel {
  name: string;
  token: string;
  isRandom?: boolean;
  isActive?: boolean;
  category?: EMediaGroupCategory;
  mediasList?: MediaModel[];
  usedInPlaylists?: PlaylistModel[];
  durationTotal?: number;

  constructor(data: any) {
    this.name = data.name;
    this.token = data.token;
    this.isRandom = data.is_random;
    this.isActive = data.is_active;
    this.category = data.category;
    this.durationTotal = data.duration_total;
    this.mediasList = [];
    this.usedInPlaylists = data.used_in_playlists
      ? data.used_in_playlists.map((data: any) => new PlaylistModel(data))
      : [];

    if (data.media_files) {
      data.media_files.forEach((media_data: any) => {
        this.mediasList.push(new MediaModel(media_data));
      });
    }
  }

  get label(): string {
    return this.name;
  }

  get isChannel(): boolean {
    return this.category === EMediaGroupCategory.channel;
  }

  get isProgram(): boolean {
    return this.category === EMediaGroupCategory.program;
  }
}

export default MediaGroupModel;
