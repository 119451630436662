import React, { Component } from "react";

import { Button } from "../../Button";
import { Paper } from "@material-ui/core";
import { GridContainer, GridItem } from "../../Grid";

import MediaGroupModel from "../../../Model/MediaGroupModel";

interface IMediaGroupItemProps {
  isDragging: boolean;
  mediaGroup: MediaGroupModel;
  index: number;
  didRemoveMedia: (index: number) => () => void;
}

class MediaGroupItem extends Component<IMediaGroupItemProps> {
  renderCategory = ({
    isChannel,
    isProgram,
    mediasList,
    isRandom,
  }: MediaGroupModel) => {
    let icon = null;
    let label = null;
    if (isChannel) {
      icon = "tv-retro";
      label = "Canal";
    } else if (isProgram) {
      icon = "film";
      label = "Programa";
    }

    label += isRandom ? " randômico" : " sequencial";

    if (label) {
      label += `${mediasList?.length || 0} mídia(s)`;
    }

    return icon ? (
      <p style={{ margin: 0, fontSize: "0.9em" }}>
        <i className={`fas fa-fw fa-${icon}`} /> {label}
      </p>
    ) : null;
  };

  render() {
    const { mediaGroup, index, didRemoveMedia, isDragging } = this.props;
    const { name } = mediaGroup;

    return (
      <Paper
        style={{
          border: isDragging ? "1px solid #1876d2" : "initial",
        }}
      >
        <GridContainer
          justify="space-between"
          alignItems="center"
          style={{ padding: "0 8px" }}
        >
          <GridItem xs sm>
            <p style={{ margin: 0, fontWeight: 500 }}>
              #{index + 1}- {name}
            </p>
            {this.renderCategory(mediaGroup)}
          </GridItem>
          <GridItem>
            <Button
              color="danger"
              small
              justIcon
              onClick={didRemoveMedia(index)}
            >
              <i className="fas fa-trash-alt" />
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
    );
  }
}

export default MediaGroupItem;
