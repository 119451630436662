import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import { InputText } from "../Form";
import { InputAdornment } from "@material-ui/core";
import { Button } from "../Button";

// Style
const styles = {
  formControl: {
    padding: 0,
    margin: 0,
  },
  marginBottom: {
    marginBottom: 20,
  },
};

interface SearchBarProps {
  classes?: any;
  label?: string;
  value: string;
  marginBottom?: boolean;
  autoFocus?: boolean;
  onChange: (search: string) => void;
}

const SearchBar: React.FunctionComponent<SearchBarProps> = ({
  classes,
  label,
  value,
  marginBottom = false,
  autoFocus = true,
  onChange,
}) => (
  <InputText
    formControlProps={{
      className: marginBottom
        ? `${classes.formControl} ${classes.marginBottom}`
        : classes.formControl,
    }}
    name="search-bar"
    placeholder={label ? label : "buscar..."}
    value={value}
    autoFocus={autoFocus}
    inputProps={{ autoComplete: "off" }}
    leftIcon={
      <InputAdornment position="start">
        <i className="fas fa-search" />
      </InputAdornment>
    }
    rightIcon={
      value.length > 0 && (
        <InputAdornment position="start">
          <Button
            color="transparent"
            justIcon
            small
            onClick={() => onChange("")}
          >
            <i className="fas fa-times" />
          </Button>
        </InputAdornment>
      )
    }
    onChange={({ target }: React.ChangeEvent<HTMLInputElement>) =>
      onChange(target.value)
    }
  />
);

export default withStyles(styles)(SearchBar);
