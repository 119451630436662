import React, { Component } from "react";

import { Button } from "../../Button";
import { Paper } from "@material-ui/core";
import { GridContainer, GridItem } from "../../Grid";

import MediaModel from "../../../Model/MediaModel";

interface IMediaItemProps {
  isDragging: boolean;
  mediaItem: MediaModel;
  index: number;
  didRemoveMedia: (index: number) => () => void;
}

class MediaItem extends Component<IMediaItemProps> {
  render() {
    const { mediaItem, index, didRemoveMedia, isDragging } = this.props;
    const { name } = mediaItem;

    return (
      <Paper
        style={{
          border: isDragging ? "1px solid #1876d2" : "initial",
        }}
      >
        <GridContainer
          justify="space-between"
          alignItems="center"
          style={{ padding: "0 8px" }}
        >
          <GridItem xs sm>
            <p style={{ margin: 0, fontWeight: 500 }}>
              #{index + 1}- {name}
            </p>
          </GridItem>
          <GridItem>
            <Button
              color="danger"
              small
              justIcon
              onClick={didRemoveMedia(index)}
            >
              <i className="fas fa-trash-alt" />
            </Button>
          </GridItem>
        </GridContainer>
      </Paper>
    );
  }
}

export default MediaItem;
