// @ts-nocheck
import React, { Component } from "react";
import { when } from "mobx";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

// Components
import { InputText, InputSwitch, InputSelect } from "../Form";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import MediaGroupItem from "./playlistConfigurationForm/MediaGroupItem";
import DragAndDropContainer from "../DragAndDrop";
import LoadingView from "../LoadingView/LoadingView";

import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { APP_ROUTES } from "../../routes/app-routes";

import { secondsToMinutesAndHours } from "../../utils/DateUtils";

import PlaylistModel from "../../Model/PlaylistModel";
import MediaGroupModel from "../../Model/MediaGroupModel";

import MediaLibraryStore from "../../store/MediaLibraryStore/MediaLibraryStore";
import PlaylistsStore from "../../store/PlaylistsStore/PlaylistsStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";

// Style
const styles = createStyles({
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
});

interface IPlaylistConfigurationFormProps {
  match: any;
  history: any;
  classes: any;
}
interface IPlaylistConfigurationFormState {
  name: string;
  mediaGroupsList: MediaGroupModel[];
  isFetching: boolean;
  isRandom: boolean;
  isMuted: boolean;
  isWaitingForm: boolean;
  formWasSubmit: boolean;
  modalIsOpen: boolean;
  wasEdited: boolean;
  isEditing: boolean;
  redirect: boolean;
  isValid: object;
}

interface InjectedProps extends IPlaylistConfigurationFormProps {
  mediaLibraryStore: MediaLibraryStore;
  playlistsStore: PlaylistsStore;
  notificationsStore: NotificationsStore;
}

@inject("mediaLibraryStore", "playlistsStore", "notificationsStore")
@observer
class PlaylistConfigurationForm extends Component<
  IPlaylistConfigurationFormProps,
  IPlaylistConfigurationFormState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  constructor(props: IPlaylistConfigurationFormProps) {
    super(props);

    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const { mediaLibraryStore, playlistsStore } = this.injected;
    if (playlistsStore.dataList.length < 1) {
      playlistsStore.getDataList();
    }
    mediaLibraryStore.requestMediaGroups();

    when(
      () =>
        !playlistsStore.isFetching && !mediaLibraryStore.isFetchingMediaGroups,
      this.setupForm,
      {
        fireImmediately: true,
      }
    );
  }

  getDefaultState = (): IPlaylistConfigurationFormState => {
    return {
      name: "",
      mediaGroupsList: [],
      isFetching: true,
      isRandom: false,
      isMuted: false,
      isWaitingForm: false,
      formWasSubmit: false,
      modalIsOpen: false,
      wasEdited: false,
      isEditing: false,
      redirect: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingPlaylist = (
    playlist: PlaylistModel
  ): IPlaylistConfigurationFormState => {
    let mediaGroupsList = [];

    playlist.mediaGroupTokensList.forEach((token) => {
      const mediaGroup = this.injected.mediaLibraryStore.getMediaGroupWithToken(
        token
      );
      if (mediaGroup) {
        mediaGroupsList.push(mediaGroup);
      }
    });

    return {
      ...this.getDefaultState(),
      name: playlist.name,
      mediaGroupsList,
      isRandom: playlist.isRandom,
      isMuted: playlist.isMuted,
      isValid: {
        name: playlist.name.length > 1,
      },
    };
  };

  setupForm = () => {
    const playlistToken = this.props.match?.params?.playlistToken;
    if (playlistToken) {
      const playlist = this.injected.playlistsStore.getWithToken(playlistToken);
      if (!playlist) {
        this.setState({
          redirect: true,
          isFetching: false,
        });
      } else {
        this.setState({
          ...this.getStateFromExistingPlaylist(playlist),
          token: playlist.token,
          isEditing: true,
          isFetching: false,
        });
      }
    } else {
      this.setState({
        isFetching: false,
      });
    }
  };

  onChangeInputText = (event: MouseEvent | TouchEvent) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeIsRandom = () => {
    this.setState((prevState) => ({
      isRandom: !prevState.isRandom,
      wasEdited: true,
    }));
  };
  onChangeIsMuted = () => {
    this.setState((prevState) => ({
      isMuted: !prevState.isMuted,
      wasEdited: true,
    }));
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    const { name } = isValid;
    if (name) {
      return true;
    }

    return false;
  };

  getFormDataToSubmit = ({ name, mediaGroupsList, isRandom, isMuted }) => ({
    name,
    mediaGroupsList,
    isRandom,
    isMuted,
  });

  didSelectMediaGroup = (_, mediaGroup: MediaGroupModel) => {
    this.setState((prevState) => ({
      mediaGroupsList: [...prevState.mediaGroupsList, mediaGroup],
      wasEdited: true,
    }));
  };

  submitForm = async () => {
    const { playlistsStore } = this.injected;
    const formData = {
      ...this.getFormDataToSubmit(this.state),
    };

    try {
      if (this.state.isEditing) {
        await playlistsStore.update({ ...formData, token: this.state.token });
      } else {
        await playlistsStore.submit(formData);
      }

      this.submitHasSuccess();
    } catch (error) {
      this.submitHasError();
    }
  };

  didCancel = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  onSubmit = (event: MouseEvent | TouchEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.injected.notificationsStore.addSnackbarNotification({
            message:
              "Erro de comunicação com o servidor. Por favor tente novamente.",
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => this.props.history.goBack();

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  didRemoveMedia = (index: number) => () => {
    this.setState((prevState) => ({
      mediaGroupsList: prevState.mediaGroupsList.filter(
        (_, mediaIndex) => mediaIndex !== index
      ),
      wasEdited: true,
    }));
  };

  didReorderList = (reorderedList: MediaGroupModel[]) => {
    this.setState({
      mediaGroupsList: reorderedList,
      wasEdited: true,
    });
  };

  calculateDurationTotal = (mediaGroupsList: MediaGroupModel[]) => {
    let total = 0;
    mediaGroupsList.forEach(({ durationTotal }) => (total += durationTotal));

    return secondsToMinutesAndHours(`${total}`);
  };

  renderMediaGroupsList = () => {
    const {
      mediaGroupsList: dataList,
      isFetchingMediaGroups,
    } = this.injected.mediaLibraryStore;
    const { mediaGroupsList } = this.state;

    return (
      <div style={{ marginBottom: 20 }}>
        <p>Ordene as mídias na sequencia de exibição</p>
        <DragAndDropContainer
          contextId="mediaGroupsListDND"
          didReorderList={this.didReorderList}
          itemsList={mediaGroupsList}
          renderItem={(
            mediaGroupItem: MediaGroupModel,
            index: number,
            isDragging: boolean
          ) => (
            <MediaGroupItem
              isDragging={isDragging}
              mediaGroup={mediaGroupItem}
              index={index}
              didRemoveMedia={this.didRemoveMedia}
            />
          )}
        />
        <InputSelect
          name="mediaGroupSelector"
          value={null}
          isLoading={isFetchingMediaGroups}
          placeholder="Adicionar grupo de mídia"
          options={dataList}
          noOptionsMessage="Nenhuma mídia encontrada"
          onChange={this.didSelectMediaGroup}
          labelKey="name"
          valueKey="token"
        />
        <p style={{ marginTop: 10 }}>
          Duração total:{" "}
          <strong>{this.calculateDurationTotal(mediaGroupsList)}</strong>
        </p>
      </div>
    );
  };

  render() {
    if (this.state.isFetching) {
      return (
        <div style={{ margin: "50px 0" }}>
          <LoadingView />
        </div>
      );
    }
    if (this.state.redirect) {
      return <Redirect to={APP_ROUTES.PLAYLIST} />;
    }

    const {
      name,
      isRandom,
      isMuted,
      formWasSubmit,
      isValid,
      isWaitingForm,
      isEditing,
    } = this.state;
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <h3 className={classes.title}>
            {isEditing ? "Editar" : "Adicionar"} playlist
          </h3>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                label="Nome"
                name="name"
                isRequired
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeInputText}
                minLength={2}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputSwitch
                label="Randômico"
                name="isRandom"
                isActive={isRandom}
                onChange={this.onChangeIsRandom}
              />
              <InputSwitch
                label="Mudo"
                name="isMuted"
                isActive={isMuted}
                onChange={this.onChangeIsMuted}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm md>
          {this.renderMediaGroupsList()}
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <GridContainer justify="space-between">
            <GridItem xs={12} sm={4}>
              <Button block onClick={this.didCancel}>
                <i className="fas fa-chevron-left" /> Cancelar
              </Button>
            </GridItem>

            <GridItem xs={12} sm={4}>
              <Button
                color="primary"
                block
                disabled={!this.state.wasEdited}
                isLoading={isWaitingForm}
                onClick={this.onSubmit}
              >
                {isEditing ? "Atualizar" : "Salvar"}{" "}
                <i className="fas fa-save" />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(PlaylistConfigurationForm);
