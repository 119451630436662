import MediaGroupModel from "./MediaGroupModel";

class MediaModel {
  name: string;
  token: string;
  duration?: number;
  creationDate?: string;
  videoUrl?: string;
  usedInMediaGroups?: MediaGroupModel[];
  selectedQty?: number;

  constructor(data: any) {
    this.name = data.name || data.file_name;
    this.token = data.token || data.file_token;
    this.duration = data.duration;
    this.creationDate = data.creation || null;
    this.videoUrl = data.url;
    this.usedInMediaGroups = data.used_in_media_groups
      ? data.used_in_media_groups.map((data: any) => new MediaGroupModel(data))
      : [];
  }

  get label(): string {
    return this.name;
  }
}

export default MediaModel;
