import React, { Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// material-ui components
import { makeStyles } from "@material-ui/styles";
import MaterialButton from "@material-ui/core/Button";

// Style
import buttonStyle from "../../assets/jss/js-styles/components/buttonStyle";
// @ts-ignore
const useStyles = makeStyles(buttonStyle);

interface IButtonProps {
  color: string;
  round?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  outline?: boolean;
  small?: boolean;
  large?: boolean;
  block?: boolean;
  link?: boolean;
  justIcon?: boolean;
  disableRipple?: boolean;
  isLoading?: boolean;
  onClick?: (event: any) => void;
  loadingMessage?: any;
  className?: string;
  customClasses?: any;
  children: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (props, ref) => {
    const {
      color,
      round,
      children,
      fullWidth,
      disabled,
      outline,
      small,
      large,
      block,
      link,
      onClick,
      isLoading,
      loadingMessage,
      justIcon,
      disableRipple,
      className,
      customClasses,
      ...rest
    } = props;
    const classes = useStyles();
    const btnClasses = classNames({
      [classes.button]: true,
      [classes["sm"]]: small,
      [classes["lg"]]: large,
      [classes[color]]: color,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled || isLoading,
      [classes.outline]: outline,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [className]: className,
    });
    return (
      <MaterialButton
        {...rest}
        innerRef={ref}
        classes={customClasses}
        className={btnClasses}
        disableRipple={disableRipple || isLoading || disabled}
        onClick={(event) => {
          if (!disabled && !isLoading && onClick) {
            onClick(event);
          }
        }}
      >
        {isLoading ? (
          <Fragment>
            {!justIcon && loadingMessage}{" "}
            <i className="fal fa-spin fa-spinner-third" />
          </Fragment>
        ) : (
          children
        )}
      </MaterialButton>
    );
  }
);

Button.propTypes = {
  color: PropTypes.oneOf([
    "default",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "white",
    "twitter",
    "facebook",
    "google",
    "linkedin",
    "pinterest",
    "youtube",
    "tumblr",
    "github",
    "behance",
    "dribbble",
    "reddit",
    "transparent",
  ]).isRequired,
  outline: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  disableRipple: PropTypes.bool,
  className: PropTypes.string,
  customClasses: PropTypes.object,
};

Button.defaultProps = {
  color: "default",
  loadingMessage: "Aguarde...",
};

export default Button;
