// @ts-nocheck
import React, { Component } from "react";
import { when } from "mobx";
import { Redirect } from "react-router-dom";
import { inject, observer } from "mobx-react";

// Components
import { InputText, InputSwitch, InputSelect, ButtonGroup } from "../Form";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";
import LoadingView from "../LoadingView/LoadingView";

import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import DragAndDropContainer from "../DragAndDrop";
import MediaItem from "./mediaGroupForm/MediaItem";

import { APP_ROUTES } from "../../routes/app-routes";

import { secondsToMinutesAndHours } from "../../utils/DateUtils";

import MediaGroupModel, {
  EMediaGroupCategory,
} from "../../Model/MediaGroupModel";
import MediaModel from "../../Model/MediaModel";

import MediaLibraryStore from "../../store/MediaLibraryStore/MediaLibraryStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";

// Style
const styles = createStyles({
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
});

interface IMediaGroupFormProps {
  match: any;
  history: any;
  classes: any;
}
interface IMediaGroupFormState {
  name: string;
  mediasList: MediaModel[];
  isFetching: boolean;
  isRandom: boolean;
  isActive: boolean;
  category: EMediaGroupCategory;
  isWaitingForm: boolean;
  formWasSubmit: boolean;
  modalIsOpen: boolean;
  wasEdited: boolean;
  isEditing: boolean;
  redirect: boolean;
  isValid: object;
}

interface InjectedProps extends IMediaGroupFormProps {
  mediaLibraryStore: MediaLibraryStore;
  notificationsStore: NotificationsStore;
}

@inject("mediaLibraryStore", "notificationsStore")
@observer
class MediaGroupForm extends Component<
  IMediaGroupFormProps,
  IMediaGroupFormState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  constructor(props: IMediaGroupFormProps) {
    super(props);

    this.state = this.getDefaultState();
  }

  componentDidMount() {
    const { mediaLibraryStore } = this.injected;
    if (mediaLibraryStore.mediaGroupsList.length < 1) {
      mediaLibraryStore.requestMediaGroups();
    }
    mediaLibraryStore.getDataList();

    when(
      () =>
        !mediaLibraryStore.isFetchingMediaGroups &&
        !mediaLibraryStore.isFetching,
      this.setupForm
    );
  }

  getDefaultState = (): IMediaGroupFormState => {
    return {
      name: "",
      mediasList: [],
      isFetching: true,
      isRandom: false,
      isActive: true,
      category: EMediaGroupCategory.channel,
      isWaitingForm: false,
      formWasSubmit: false,
      modalIsOpen: false,
      wasEdited: false,
      isEditing: false,
      redirect: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingMediaGroup = (
    mediaGroup: MediaGroupModel
  ): IMediaGroupFormState => {
    return {
      ...this.getDefaultState(),
      name: mediaGroup.name,
      mediasList: mediaGroup.mediasList,
      isRandom: mediaGroup.isRandom,
      isActive: mediaGroup.isActive,
      category: mediaGroup.category,
      isValid: {
        name: mediaGroup.name.length > 1,
      },
    };
  };

  setupForm = () => {
    const mediaGroupToken = this.props.match?.params?.mediaGroupToken;
    if (mediaGroupToken) {
      const mediaGroup = this.injected.mediaLibraryStore.getMediaGroupWithToken(
        mediaGroupToken
      );
      if (!mediaGroup) {
        this.setState({
          redirect: true,
          isFetching: false,
        });
      } else {
        this.setState({
          ...this.getStateFromExistingMediaGroup(mediaGroup),
          token: mediaGroup.token,
          isEditing: true,
          isFetching: false,
        });
      }
    } else {
      this.setState({
        isFetching: false,
      });
    }
  };

  onChangeInputText = (event: MouseEvent | TouchEvent) => {
    const { name: key, value, dataset, minLength, maxLength } = event.target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  onChangeIsRandom = () => {
    this.setState((prevState) => ({
      isRandom: !prevState.isRandom,
      wasEdited: true,
    }));
  };
  onChangeIsActive = () => {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
      wasEdited: true,
    }));
  };
  onChangeCategory = (_, category: EMediaGroupCategory) => {
    this.setState({
      category,
      wasEdited: true,
    });
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    const { name } = isValid;
    if (name) {
      return true;
    }

    return false;
  };

  getFormDataToSubmit = ({
    name,
    mediasList,
    isRandom,
    isActive,
    category,
  }) => ({
    name,
    mediasList: mediasList.map(({ token }) => token),
    isRandom,
    isActive,
    category,
  });

  didSelectMediaGroup = (_, mediaGroup: MediaModel) => {
    this.setState((prevState) => ({
      mediasList: [...prevState.mediasList, mediaGroup],
      wasEdited: true,
    }));
  };

  submitForm = async () => {
    const { mediaLibraryStore } = this.injected;
    const formData = {
      ...this.getFormDataToSubmit(this.state),
    };

    try {
      if (this.state.isEditing) {
        await mediaLibraryStore.updateMediaGroup({
          ...formData,
          token: this.state.token,
        });
      } else {
        await mediaLibraryStore.submitMediaGroup(formData);
      }

      this.submitHasSuccess();
    } catch (error) {
      this.submitHasError();
    }
  };

  didCancel = (event: MouseEvent | TouchEvent) => {
    event.preventDefault();

    this.props.history.goBack();
  };

  onSubmit = (event: MouseEvent | TouchEvent) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.injected.notificationsStore.addSnackbarNotification({
            message:
              "Erro de comunicação com o servidor. Por favor tente novamente.",
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => this.props.history.goBack();

  submitHasError = () => {
    this.setState({
      isWaitingForm: false,
    });
  };

  didRemoveMedia = (index: number) => () => {
    this.setState((prevState) => ({
      mediasList: prevState.mediasList.filter(
        (_, mediaIndex) => mediaIndex !== index
      ),
      wasEdited: true,
    }));
  };

  didReorderList = (reorderedList: MediaModel[]) => {
    this.setState({
      mediasList: reorderedList,
      wasEdited: true,
    });
  };

  calculateDurationTotal = (mediasList: MediaModel[]) => {
    let total = 0;
    mediasList.forEach(({ duration }) => (total += duration || 0));

    return secondsToMinutesAndHours(`${total}`);
  };

  renderMediasList = () => {
    const { dataList, isFetching } = this.injected.mediaLibraryStore;
    const { mediasList } = this.state;

    return (
      <div style={{ marginBottom: 20 }}>
        <p>Ordene as mídias na sequencia de exibição</p>
        <DragAndDropContainer
          contextId="mediasListDND"
          didReorderList={this.didReorderList}
          itemsList={mediasList}
          renderItem={(
            mediaItem: MediaModel,
            index: number,
            isDragging: boolean
          ) => (
            <MediaItem
              isDragging={isDragging}
              mediaItem={mediaItem}
              index={index}
              didRemoveMedia={this.didRemoveMedia}
            />
          )}
        />
        <InputSelect
          name="mediaGroupSelector"
          value={null}
          isLoading={isFetching}
          placeholder="Adicionar grupo de mídia"
          options={dataList}
          noOptionsMessage="Nenhuma mídia encontrada"
          onChange={this.didSelectMediaGroup}
          labelKey="name"
          valueKey="token"
        />
        <p style={{ marginTop: 10 }}>
          Duração total:{" "}
          <strong>{this.calculateDurationTotal(mediasList)}</strong>
        </p>
      </div>
    );
  };

  render() {
    if (this.state.isFetching) {
      return (
        <div style={{ margin: "50px 0" }}>
          <LoadingView />
        </div>
      );
    }
    if (this.state.redirect) {
      return <Redirect to={APP_ROUTES.MEDIA_GROUPS} />;
    }

    const {
      name,
      isRandom,
      isActive,
      category,
      formWasSubmit,
      isValid,
      isWaitingForm,
      isEditing,
    } = this.state;
    const { classes } = this.props;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <h3 className={classes.title}>
            {isEditing ? "Editar" : "Adicionar"} grupo de mídias
          </h3>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <GridContainer>
            <GridItem xs={12}>
              <InputText
                label="Nome"
                name="name"
                isRequired
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeInputText}
                minLength={2}
              />
              <ButtonGroup
                name="category"
                value={category}
                whiteBackgroundColor
                large
                onChange={this.onChangeCategory}
                options={[
                  {
                    value: EMediaGroupCategory.channel,
                    label: (
                      <span>
                        Canal <i className="fas fa-tv-retro" />
                      </span>
                    ),
                  },
                  {
                    value: EMediaGroupCategory.program,
                    label: (
                      <span>
                        Programa <i className="fas fa-film" />
                      </span>
                    ),
                  },
                ]}
              />
              {category === EMediaGroupCategory.channel && (
                <p>
                  Apenas <strong>uma mídia</strong> do grupo será incluída na
                  sequência da programação.
                </p>
              )}
              {category === EMediaGroupCategory.program && (
                <p>
                  <strong>Todas as mídias</strong> do grupo serão incluídas na
                  sequência da programação.
                </p>
              )}
            </GridItem>
            <GridItem xs={12}>
              <InputSwitch
                label="Randômico"
                name="isRandom"
                isActive={isRandom}
                onChange={this.onChangeIsRandom}
              />
              <InputSwitch
                label="Habilitado"
                name="isActive"
                isActive={isActive}
                onChange={this.onChangeIsActive}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm md>
          {this.renderMediasList()}
        </GridItem>
        <GridItem xs={12} style={{ marginTop: 20 }}>
          <GridContainer justify="space-between">
            <GridItem xs={12} sm={4}>
              <Button block onClick={this.didCancel}>
                <i className="fas fa-chevron-left" /> Cancelar
              </Button>
            </GridItem>

            <GridItem xs={12} sm={4}>
              <Button
                color="primary"
                block
                disabled={!this.state.wasEdited}
                isLoading={isWaitingForm}
                onClick={this.onSubmit}
              >
                {isEditing ? "Atualizar" : "Salvar"}{" "}
                <i className="fas fa-save" />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(styles)(MediaGroupForm);
