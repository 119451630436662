// @ts-nocheck
import React, { Component, Fragment } from "react";
import { inject } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";

// Components
import { InputText } from "../Form";
import InputFile, { UploadMediaFile } from "../Form/InputFile";
import LoadingView from "../LoadingView/LoadingView";
import Conditional from "../Conditional/Conditional";

import { GridContainer, GridItem } from "../Grid";
import { Button } from "../Button";

// Style
const styles = {
  image: {
    maxWidth: "100%",
    maxHeight: 200,
    padding: 2,
    borderRadius: 8,
    background: "#ADADAD",
  },
  imagePlaceholder: {
    height: 56,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    position: "relative",
    width: "fit-content",
  },
  titleHelpIcon: {
    fontSize: 16,
    position: "absolute",
    right: -22,
    top: 4,
  },
};

@inject("mediaLibraryStore", "notificationsStore")
class MediaFileForm extends Component {
  constructor(props) {
    super(props);

    if (props.mediaItem) {
      this.state = this.getStateFromExistingMediaFile(props.mediaItem);
    } else {
      this.state = this.getDefaultState();
    }
  }

  getDefaultState = () => {
    return {
      name: "",
      videoFile: null,
      duration: "",
      isWaitingForm: false,
      formWasSubmit: false,
      didSelectFile: false,
      modalIsOpen: false,
      wasEdited: false,
      isValid: {
        name: false,
      },
    };
  };

  getStateFromExistingMediaFile = (mediaItem) => {
    return {
      ...this.getDefaultState(),
      didSelectFile: true,
      name: mediaItem.name,
      videoUrl: mediaItem.videoUrl,
      duration: mediaItem.duration,
      isValid: {
        name: mediaItem.name.length >= 2,
      },
    };
  };

  onChangeInputText = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name: key, value, dataset, minLength, maxLength } = target;
    const validateIsRequired = dataset
      ? dataset.required === "true"
        ? value !== ""
        : true
      : true;

    const validateMinLength = minLength > 0 ? value.length >= minLength : true;
    const validateMaxLength = maxLength > 0 ? value.length <= maxLength : true;

    this.setState((state) => ({
      [key]: value,
      isValid: {
        ...state.isValid,
        [`${key}`]:
          validateIsRequired && validateMinLength && validateMaxLength,
      },
      wasEdited: true,
    }));
  };

  canSubmitForm = () => {
    const { isValid } = this.state;
    const { name } = isValid;
    if (name) {
      return true;
    }

    return false;
  };

  onChangeFileUpload = (
    _: string,
    { name, size, type, duration, base64 }: UploadMediaFile
  ) => {
    this.setState((prevState) => {
      const fileName =
        prevState.name.length > 0 ? prevState.name : name.replace(".mp4", "");
      return {
        didSelectFile: true,
        videoFile: {
          name,
          size,
          type,
          base64,
        },
        name: fileName,
        duration: duration.toFixed(0),
        wasEdited: true,
        isValid: {
          name: fileName.length >= 2,
        },
      };
    });
  };

  getFormDataToSubmit = ({ name, videoFile, duration }) => ({
    name,
    duration,
    videoFile,
  });

  submitForm = async () => {
    const { mediaLibraryStore } = this.props;
    const formData = this.getFormDataToSubmit(this.state);

    const sendToServer = async () => {
      if (this.props.mediaItem) {
        await mediaLibraryStore.update({
          ...this.props.mediaItem,
          ...formData,
        });
      } else {
        await mediaLibraryStore.submit(formData);
      }
    };

    try {
      await sendToServer();
      this.submitHasSuccess();
    } catch (_) {
      this.submitHasError();
    }
  };

  didCancel = (event) => {
    event.preventDefault();

    this.props.didCancel();
  };

  onSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.canSubmitForm()) {
      this.setState({ isWaitingForm: true, formWasSubmit: true }, () => {
        this.props.didChangeUploadStatus(true);
        this.submitForm();
      });
    } else {
      this.setState(
        {
          formWasSubmit: true,
        },
        () => {
          this.props.notificationsStore.addSnackbarNotification({
            message:
              "Erro de comunicação com o servidor. Por favor tente novamente.",
            color: "danger",
          });
        }
      );
    }
  };

  submitHasSuccess = () => {
    this.props.didChangeUploadStatus(false);
    this.props.didCancel();
  };

  submitHasError = () => {
    this.props.didChangeUploadStatus(false);
    this.setState({
      isWaitingForm: false,
    });
  };

  renderMediaFile = () => {
    const { videoFile, videoUrl } = this.state;
    const { mediaLibraryStore } = this.props;

    return (
      <InputFile
        name="videoUrl"
        label="Mídia"
        onChangeFile={this.onChangeFileUpload}
        accept="video/mp4"
      >
        {(videoUrl || videoFile) && (
          <div style={{ marginBottom: 10 }}>
            <video style={{ width: "100%", maxHeight: 400 }} controls>
              <source
                src={
                  videoFile
                    ? videoFile.base64
                    : mediaLibraryStore.getMediaVideoUrl(videoUrl)
                }
                type="video/mp4"
              />
            </video>
          </div>
        )}
      </InputFile>
    );
  };

  render() {
    const { classes, mediaItem } = this.props;
    const isEditing = mediaItem !== null;

    const {
      name,
      duration,
      didSelectFile,
      formWasSubmit,
      isValid,
      isWaitingForm,
    } = this.state;

    if (isWaitingForm) {
      return (
        <div style={{ margin: "120px 0" }}>
          <LoadingView height={120} />
        </div>
      );
    }

    return (
      <Fragment>
        <GridContainer>
          <GridItem xs={12}>
            <h3 className={classes.title}>
              {isEditing ? "Editar" : "Adicionar"} mídia
            </h3>
          </GridItem>
          <GridItem xs={12}>{this.renderMediaFile()}</GridItem>

          <Conditional when={didSelectFile}>
            <GridItem xs={12}>
              <InputText
                label="Nome"
                name="name"
                isRequired
                hasError={formWasSubmit && !isValid.name}
                hasSuccess={formWasSubmit && isValid.name}
                value={name}
                onChange={this.onChangeInputText}
                minLength={2}
              />
            </GridItem>
            <GridItem xs={12}>
              <InputText
                label="Duração"
                name="duration"
                inputProps={{
                  autoComplete: "off",
                }}
                value={`${duration}`}
                onChange={this.onChangeInputText}
                helpText="Em segundos"
              />
            </GridItem>
          </Conditional>
        </GridContainer>
        <GridContainer justify="space-between">
          <GridItem xs={12} sm={4}>
            <Button block onClick={this.didCancel} disabled={isWaitingForm}>
              <i className="fas fa-chevron-left" /> Cancelar
            </Button>
          </GridItem>
          <GridItem xs={12} sm={4}>
            <Button
              color="primary"
              block
              disabled={!this.state.wasEdited}
              isLoading={isWaitingForm}
              onClick={this.onSubmit}
            >
              {isEditing ? "Atualizar" : "Salvar"} <i className="fas fa-save" />
            </Button>
          </GridItem>
        </GridContainer>
      </Fragment>
    );
  }
}

export default withStyles(styles)(MediaFileForm);
