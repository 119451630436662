// @ts-nocheck
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Button from "./Button";

const LinkButton = ({
  history,
  location,
  match,
  staticContext,
  to,
  onClick,
  color = "transparent",
  // ⬆ filtering out props that `button` doesn’t know what to do with.
  ...rest
}) => (
  <Button
    {...rest}
    color={color}
    onClick={event => {
      onClick && onClick(event);
      history.push(to);
    }}
  />
);

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default withRouter(LinkButton);
