import React, { Component } from "react";
import _debounce from "lodash/debounce";

import withStyles from "@material-ui/core/styles/withStyles";
import { Button, FormControl } from "@material-ui/core";
import Conditional from "../Conditional/Conditional";
import LoadingView from "../LoadingView/LoadingView";

// Style
import customInputStyle from "../../assets/jss/js-styles/components/customInputStyle";

export interface UploadMediaFile {
  name: string;
  size: number;
  duration?: number;
  type: string;
  base64: string | ArrayBuffer;
}

interface InputFileProps {
  classes: any;
  accept?: string;
  multiple?: boolean;
  name: string;
  label: string;
  disabled?: boolean;
  onChangeFile: (name: string, file: UploadMediaFile) => void;
}

interface InputFileState {
  isLoading?: boolean;
  file?: UploadMediaFile | null;
}

class InputFile extends Component<InputFileProps, InputFileState> {
  static defaultProps = {
    accept: "image/*",
    multiple: false,
  };

  state = {
    isLoading: false,
    file: null,
  };

  onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        Array.from(target.files).forEach((file) => {
          this.getVideoDuration(file);

          this.didChangeFile(file);
        });
      }
    );
  };

  getVideoDuration = (file: File): void => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src);

      this.setState(
        (prevState) => ({
          file: {
            ...prevState.file,
            duration: videoElement.duration,
          },
        }),
        () => this.notifyParent()
      );
    };

    videoElement.src = URL.createObjectURL(file);
  };

  didChangeFile = (file: File) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => {
      this.setState(
        (prevState) => ({
          file: {
            ...prevState.file,
            name: file.name,
            size: file.size,
            type: file.type,
            base64: e.target.result,
          },
        }),
        () => this.notifyParent()
      );
    };
  };

  notifyParent = _debounce(() => {
    const { name, size, duration, type, base64 } = this.state.file;
    this.props.onChangeFile(this.props.name, {
      name,
      size,
      duration,
      type,
      base64,
    });

    this.setState({
      isLoading: false,
    });
  }, 2000);

  render() {
    const {
      classes,
      accept,
      multiple,
      name,
      label,
      disabled,
      children,
    } = this.props;

    const { isLoading } = this.state;

    return (
      <FormControl
        disabled={disabled}
        fullWidth
        className={classes.formControl}
        style={{ paddingTop: 8 }}
      >
        <p
          style={{
            color: "#AAAAAA",
            fontSize: 12,
            fontWeight: 500,
            textTransform: "uppercase",
            margin: 0,
          }}
        >
          {label}
        </p>
        {children}
        <input
          accept={accept}
          style={{ display: "none" }}
          id={name}
          multiple={multiple}
          type="file"
          onChange={this.onChange}
        />
        <Conditional when={isLoading}>
          <LoadingView height={40} />
        </Conditional>
        <Conditional when={!isLoading}>
          <label htmlFor={name}>
            <Button variant="contained" component="span">
              Escolher arquivo
            </Button>
          </label>
        </Conditional>
      </FormControl>
    );
  }
}

export default withStyles(customInputStyle)(InputFile);
