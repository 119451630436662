import React, { Component, CSSProperties, ReactElement } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import DragAndDropItem from "./DragAndDropItem";

interface IDragAndDropContainerProps<T> {
  contextId: string;
  itemsList: T[];
  didReorderList: (reorderedList: T[]) => void;
  renderContainer?: (isDraggingOver: boolean) => ReactElement;
  renderItem: (item: T, index: number, isDragging: boolean) => ReactElement;
}

class DragAndDropContainer<T> extends Component<IDragAndDropContainerProps<T>> {
  getListStyle = (isDraggingOver: boolean): CSSProperties => ({
    background: isDraggingOver ? "lightgrey" : "transparent",
    padding: 8,
    border: "1px solid lightgrey",
    borderRadius: 6,
    marginBottom: 6,
  });

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsList: T[] = this.reorder(
      this.props.itemsList,
      result.source.index,
      result.destination.index
    );

    this.props.didReorderList(itemsList);
  };

  reorder = (list, startIndex, endIndex): T[] => {
    const result: T[] = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    const { itemsList, contextId, renderContainer, renderItem } = this.props;
    if (itemsList.length < 1) return null;

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId={contextId}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={this.getListStyle(snapshot.isDraggingOver)}
            >
              {renderContainer && renderContainer(snapshot.isDraggingOver)}
              {itemsList.map((item, index) => (
                <DragAndDropItem
                  contextId={contextId}
                  key={index}
                  index={index}
                >
                  {(isDragging) => renderItem(item, index, isDragging)}
                </DragAndDropItem>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DragAndDropContainer;
