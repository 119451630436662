// @ts-nocheck
import React, { Component } from "react";
import Logger from "simplyas-js-logger";

import { GridFullScreen } from "./Grid";

interface IErrorCatcherProps {
  children: any;
  showErrorOnTheScreen: boolean;
}

class ErrorCatcher extends Component<IErrorCatcherProps> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  saveComponentErrorLog = (log: any) => {
    Logger.error("ComponentErrorLog", log);
  };

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState(
      {
        hasError: true,
      },
      () => {
        this.saveComponentErrorLog({
          error: error && error.toString(),
          ...errorInfo,
        });
      }
    );
  }

  render() {
    if (this.props.showErrorOnTheScreen && this.state.hasError) {
      return (
        <GridFullScreen>
          <p style={{ fontSize: "1.2em" }}>
            <i
              className="fa fa-exclamation-triangle"
              style={{ marginRight: 6 }}
            />
            Ocorreu um erro inesperado. Em caso de dúvidas entre em contato com
            o suporte.
          </p>
        </GridFullScreen>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

ErrorCatcher.defaultProps = {
  showErrorOnTheScreen: true,
};

export default ErrorCatcher;
