import React, { Component } from "react";
import classnames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { Button } from "../../components/Button";
import { SimpleCard } from "../../components/Card";
import { GridContainer, GridItem } from "../../components/Grid";
import Tooltip from "../../components/Tooltip/Tooltip";

import ScheduleText from "../../components/ScheduleText/ScheduleText";
import { ScheduleInterface } from "../../Model/PlaylistScheduleModel";

// Style
import { dangerColor } from "../../assets/jss/app-js-styles";
const styles = createStyles({
  card: { margin: 0, marginBottom: 16 },
  container: {
    padding: "8px 16px",
    position: "relative",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid transparent",
  },
  isInactiveContainer: {
    borderColor: dangerColor,
  },
  titleContainer: {
    position: "relative",
  },
  title: {
    margin: 0,
    fontSize: "1.4em",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
});

const inativeLabelStyle = (isActive: boolean) => ({
  textDecoration: isActive ? "none" : "line-through",
  color: isActive ? "inherit" : "#ADADAD",
});

interface ScheduleRuleForCompanyProps {
  classes?: any;
  canDrag: boolean;
  isDragging: boolean;
  didWantToEditSchedule: (token: string) => void;
  token: string;
  label: string;
  isActive: boolean;
  schedule: ScheduleInterface;
}

class ScheduleRuleForCompany extends Component<ScheduleRuleForCompanyProps> {
  render() {
    const {
      classes,
      isDragging,
      didWantToEditSchedule,
      token,
      label,
      isActive,
      schedule,
    } = this.props;
    return (
      <SimpleCard
        key={token}
        classNames={{
          card: classes.card,
          cardBody: classnames({
            [classes.container]: true,
            [classes.isInactiveContainer]: !isActive,
          }),
        }}
        cardStyle={{ border: isDragging ? "1px solid #1876d2" : "initial" }}
      >
        <GridContainer>
          <GridItem xs={12} className={classes.titleContainer}>
            <h3 className={classes.title} style={inativeLabelStyle(isActive)}>
              {label}
            </h3>
          </GridItem>
          <GridItem xs={12}>
            <ScheduleText
              style={inativeLabelStyle(isActive)}
              schedule={schedule}
            />
          </GridItem>
        </GridContainer>
        {/* @ts-ignore */}
        <Tooltip title="Editar">
          <Button
            small
            justIcon
            color="info"
            onClick={() => didWantToEditSchedule(token)}
          >
            <i className="fa fa-pen" />
          </Button>
        </Tooltip>
      </SimpleCard>
    );
  }
}

export default withStyles(styles)(ScheduleRuleForCompany);
