// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";
import Table from "../components/Table/Table";
import Modal from "../components/Modal/Modal";
import AlertModal from "../components/AlertModal/AlertModal";
import MediaFileForm from "../components/AppForm/MediaFileForm";
import { Button, LinkButton } from "../components/Button";
import { GridContainer, GridItem } from "../components/Grid";
import { SimpleCard } from "../components/Card";
import SearchBar from "../components/SearchBar/SearchBar";

import { secondsToMinutesAndHours } from "../utils/DateUtils";

import { APP_ROUTES } from "../routes/app-routes";

@inject("mediaLibraryStore")
@observer
class MediaLibraryPage extends Component {
  state = {
    isOpenModal: false,
    selectedMedia: null,
    isAddingMedia: false,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.mediaLibraryStore.getDataList();
  }

  getTableData = () => {
    const { filteredMediaList } = this.props.mediaLibraryStore;

    return filteredMediaList.map(({ name, duration, creationDate, token }) => ({
      name: name,
      duration: secondsToMinutesAndHours(duration),
      uploadDate: creationDate,
      actions: token,
    }));
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
      isOpenModal: false,
      selectedMedia: null,
    });
  };

  didDeleteConfiguration = () => {
    this.props.mediaLibraryStore
      .delete(this.state.selectedMedia.token)
      .then(() => {
        this.setState({
          didWantToDelete: false,
          isOpenModal: false,
          selectedMedia: null,
        });
      });
  };

  didWantToDeleteWithToken = (token: string) => async () => {
    const selectedMedia = await this.props.mediaLibraryStore.requestFile(token);

    if (selectedMedia) {
      this.setState({
        didWantToDelete: true,
        selectedMedia,
      });
    }
  };

  didWantToEditWithToken = (token) => () => {
    const selectedMedia = this.props.mediaLibraryStore.getWithToken(token);
    this.setState({
      isOpenModal: true,
      selectedMedia,
    });
  };

  didWantToAddMedia = () => {
    this.setState({
      isOpenModal: true,
      isAddingMedia: true,
    });
  };

  didChangeUploadStatus = (status) => {
    this.setState({
      isUploading: status,
    });
  };

  didCloseModal = () => {
    if (this.state.isUploading) return false;
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          this.setState({
            selectedMedia: null,
            isAddingMedia: false,
          });
        }, 200);
      }
    );
  };

  render() {
    const { mediaLibraryStore } = this.props;
    const {
      isOpenModal,
      selectedMedia,
      isAddingMedia,
      didWantToDelete,
    } = this.state;

    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Biblioteca de Mídias</PageTitle>

        {didWantToDelete && (
          <AlertModal
            warning
            title="Confirmar esta ação"
            onConfirm={this.didDeleteConfiguration}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel="Apagar"
            cancelButtonLabel="Cancelar"
            showCancel
          >
            {selectedMedia.usedInMediaGroups.length > 0 && (
              <div style={{ textAlign: "left" }}>
                <p style={{ margin: 0 }}>
                  Esta mídia está sendo utilizada na(s) seguinte(s) grupo(s) de
                  mídias:
                </p>
                <ul style={{ marginTop: 0 }}>
                  {selectedMedia.usedInMediaGroups.map(({ name, token }) => (
                    <li key={token} style={{ listStyleType: "none" }}>
                      <LinkButton
                        color="transparent"
                        small
                        style={{ margin: 0, padding: "4px 0" }}
                        to={`${APP_ROUTES.MEDIA_GROUPS}/editar/${token}`}
                        target="_blank"
                      >
                        <i className="far fa-hand-point-right" /> {name}
                      </LinkButton>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            <i className="fas fa-exclamation-triangle" /> Esta ação não poderá
            ser desfeita.
          </AlertModal>
        )}
        {(selectedMedia || isAddingMedia) && !didWantToDelete && (
          <Modal
            isOpen={isOpenModal}
            hideCloseButton={this.state.isUploading}
            maxWidth={"sm"}
            didCloseModal={this.didCloseModal}
          >
            <MediaFileForm
              mediaItem={isAddingMedia ? null : selectedMedia}
              didCancel={this.didCloseModal}
              didChangeUploadStatus={this.didChangeUploadStatus}
            />
          </Modal>
        )}
        <GridContainer style={{ marginBottom: 20 }}>
          {mediaLibraryStore.dataList.length > 5 && (
            <GridItem xs sm md lg>
              <SimpleCard cardStyle={{ marginTop: 0, marginBottom: 0 }}>
                <SearchBar
                  value={mediaLibraryStore.searchString}
                  onChange={mediaLibraryStore.setSearchString}
                />
              </SimpleCard>
            </GridItem>
          )}
        </GridContainer>
        <SimpleCard>
          <GridContainer>
            <GridItem xs={12}>
              <Button color="primary" onClick={this.didWantToAddMedia} small>
                Adicionar mídia <i className="fas fa-plus" />
              </Button>
            </GridItem>
            <GridItem xs={12}>
              <Table
                columns={[
                  {
                    Header: "Nome",
                    accessor: "name",
                  },
                  {
                    Header: "Duração",
                    accessor: "duration",
                  },
                  {
                    Header: "Data de upload",
                    accessor: "uploadDate",
                  },
                ]}
                data={this.getTableData()}
                actions={[
                  {
                    type: "delete",
                    onClick: this.didWantToDeleteWithToken,
                  },
                  {
                    type: "edit",
                    onClick: this.didWantToEditWithToken,
                  },
                ]}
              />
            </GridItem>
          </GridContainer>
        </SimpleCard>
      </div>
    );
  }
}

export default MediaLibraryPage;
