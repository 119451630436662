type ContractRuleDvr = {
  admin: boolean;
  supervisor: boolean;
  analyst: boolean;
  operator: boolean;
  semob: boolean;
  garage_manager: boolean;
};

type ContractRuleSys = {
  admin: boolean;
  operator: boolean;
  maintenance: boolean;
};

export interface ContractRules {
  sys: ContractRuleSys;
  dvr: ContractRuleDvr;
}

export const CONTRACT_DEFAULT_RULES: ContractRules = {
  sys: {
    admin: false,
    operator: false,
    maintenance: false,
  },
  dvr: {
    admin: false,
    supervisor: false,
    analyst: false,
    operator: false,
    semob: false,
    garage_manager: false,
  },
};

class ContractModel {
  hash: string;
  name: string;
  rules: ContractRules;

  constructor(contractData: any) {
    this.hash = contractData.contract_hash || contractData.token;
    this.name = contractData.contract_name || contractData.name;
    this.rules = contractData.rules || CONTRACT_DEFAULT_RULES;
  }

  get label() {
    return this.name;
  }
}

export default ContractModel;
