class PlaylistModel {
  name: string;
  token: string;
  isRandom?: boolean;
  isMuted?: boolean;
  mediaGroupTokensList?: string[];

  constructor(playlist: any) {
    this.name = playlist.name;
    this.token = playlist.token;
    this.isRandom = playlist.is_random;
    this.isMuted = playlist.is_muted;
    this.mediaGroupTokensList = playlist.media_groups || [];
  }

  get label(): string {
    return this.name;
  }
}

export default PlaylistModel;
