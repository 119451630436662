import { observable, computed, action } from "mobx";
import Logger from "simplyas-js-logger";

// Model
import MediaGroupModel from "../../Model/MediaGroupModel";
import MediaModel from "../../Model/MediaModel";

import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import FileService from "../../services/FileService";
import MobR2APIService from "../../services/MobR2APIService";

// Util
import { didSearchList } from "../../utils/Utils";

class MediaLibraryStore {
  internalEventsStore: InternalEventsStore;
  notificationsStore: NotificationsStore;
  mobR2APIService: MobR2APIService;
  fileService: FileService;

  @observable dataList: MediaModel[] = [];
  @observable
  mediaGroupsList: MediaGroupModel[] = [];
  @observable
  isFetching: boolean = false;
  @observable
  isFetchingMediaGroups: boolean = false;
  @observable searchString: string = "";

  constructor(
    internalEventsStore: InternalEventsStore,
    notificationsStore: NotificationsStore,
    mobR2APIService: MobR2APIService,
    fileService: FileService
  ) {
    this.notificationsStore = notificationsStore;
    this.mobR2APIService = mobR2APIService;
    this.fileService = fileService;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  clearStore = () => {
    this.dataList = [];
    this.resetMediasSelectedQty();
    this.searchString = "";
  };

  @action
  setSearchString = (searchString: string) => {
    this.searchString = searchString;
  };

  @action
  setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };
  @action
  setIsFetchingMediaGroups = (newValue: boolean) => {
    this.isFetchingMediaGroups = newValue;
  };
  @action
  setDataList = (newList: MediaModel[]) => {
    this.dataList = newList;
  };
  @action
  setMediaGroupsList = (newList: MediaGroupModel[]) => {
    this.mediaGroupsList = newList;
  };

  updateMediasList = (withFetchingAnimation = true) => {
    this.setIsFetching(withFetchingAnimation);

    this.getDataList();
  };

  @computed
  get filteredMediaList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.dataList,
        (mediaItem, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(mediaItem.name);
          const matchCreationDate = textIncludesSearchString(
            mediaItem.creationDate
          );

          return matchName || matchCreationDate;
        }
      );
    }
    return this.dataList;
  }

  @computed
  get filteredMediaGroupsList() {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.mediaGroupsList,
        (mediaGroupItem: MediaGroupModel, textIncludesSearchString) => {
          const matchName = textIncludesSearchString(mediaGroupItem.name);
          const matchCategory = textIncludesSearchString(
            mediaGroupItem.category
          );

          return matchName || matchCategory;
        }
      );
    }
    return this.mediaGroupsList;
  }

  saveSelectedMediasBackup = () => {
    const selectedMediasBackup = {};
    if (this.dataList.length > 0) {
      this.dataList
        .filter((mediaItem) => mediaItem.selectedQty > 0)
        .forEach((mediaItem) => {
          selectedMediasBackup[mediaItem.token] = mediaItem.selectedQty;
        });
    }
    return selectedMediasBackup;
  };

  getDataList = async () => {
    try {
      let newMediasList = [];
      const selectedMediasBackup = this.saveSelectedMediasBackup();

      const response: any = await this.mobR2APIService.requestFiles();
      if (response.status === 200) {
        response.data.forEach((mediaItemData: any) => {
          newMediasList.push({
            ...new MediaModel(mediaItemData),
            selectedQty: selectedMediasBackup[mediaItemData.token]
              ? selectedMediasBackup[mediaItemData.token]
              : 0,
          });
        });

        this.setDataList(newMediasList);
      }
    } catch (error) {
      Logger.error("error in getDataList", error);
    } finally {
      this.setIsFetching(false);
    }
  };

  submit = async (mediaData: MediaModel) => {
    try {
      const response: any = await this.mobR2APIService.submitFile(mediaData);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      await this.getDataList();
    } catch (error) {
      Logger.error("error submitting File", error);
    }
  };

  update = async (mediaData: MediaModel) => {
    try {
      const response: any = await this.mobR2APIService.updateFile(mediaData);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.getDataList();
    } catch (error) {
      Logger.error("error updating File", error);
    }
  };

  @action
  resetMediasSelectedQty = () => {
    this.dataList = this.dataList.map(
      (mediaItem) =>
        new MediaModel({
          ...mediaItem,
          selectedQty: 0,
        })
    );
  };

  @computed
  get getSelectedMedias() {
    return this.dataList.filter((mediaItem) => mediaItem.selectedQty > 0);
  }

  @action
  loopListToChangeSelectedQty = (listName, props) => {
    let newList = [];
    this[listName].forEach((mediaItem) => {
      if (mediaItem.token === props.token) {
        newList.push({
          ...mediaItem,
          selectedQty: props.selectedQty,
        });
      } else {
        newList.push(mediaItem);
      }
    });
    this[listName] = newList;
  };

  delete = async (token: string) => {
    this.setIsFetching(true);
    try {
      const response: any = await this.mobR2APIService.deleteFile(token);
      await this.getDataList();
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "warning",
      });
    } finally {
      this.setIsFetching(false);
    }
  };

  getWithToken = (token: string): MediaModel => {
    return this.dataList.find((mediaItem) => mediaItem.token === token);
  };

  getMediaGroupWithToken = (token: string): MediaGroupModel => {
    return this.mediaGroupsList.find(
      (mediaGroup) => mediaGroup.token === token
    );
  };

  requestMediaGroups = async () => {
    this.setIsFetchingMediaGroups(true);
    try {
      let tempList = [];
      const response: any = await this.mobR2APIService.requestMediaGroups();
      if (response.status === 200) {
        response.data.forEach((data: any) => {
          const mediaGroup = new MediaGroupModel(data);
          tempList.push(mediaGroup);
        });
      }
      this.setMediaGroupsList(tempList);
    } catch (error) {
      Logger.error("error in requestMediaGroups", error);
    } finally {
      this.setIsFetchingMediaGroups(false);
    }
  };

  requestMediaGroup = async (token: string) => {
    try {
      const response: any = await this.mobR2APIService.requestMediaGroup(token);
      if (response.status === 200) {
        return new MediaGroupModel(response.data);
      }
    } catch (error) {
      Logger.error("error in requestMediaGroup", error);
    }
    return null;
  };
  requestFile = async (token: string) => {
    try {
      const response: any = await this.mobR2APIService.requestFile(token);
      if (response.status === 200) {
        return new MediaModel(response.data);
      }
    } catch (error) {
      Logger.error("error in requestFile", error);
    }
    return null;
  };

  getMediaVideoUrl = (url: string) => this.fileService.mountFileUrl(url);

  submitMediaGroup = async (mediaGroupData: any) => {
    try {
      const response: any = await this.mobR2APIService.submitMediaGroup(
        mediaGroupData
      );
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.requestMediaGroups();
    } catch (error) {
      Logger.error("error in submitMediaGroup", error);
    }
  };

  updateMediaGroup = async (mediaGroupData: any) => {
    try {
      const response: any = await this.mobR2APIService.updateMediaGroup(
        mediaGroupData
      );
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "success",
      });
      this.requestMediaGroups();
    } catch (error) {
      Logger.error("error in updateMediaGroup", error);
    }
  };

  duplicateMediaGroup = async (token: string) => {
    this.setIsFetchingMediaGroups(true);
    const mediaGroup = this.getMediaGroupWithToken(token);
    if (!mediaGroup) return;
    try {
      const response: any = await this.mobR2APIService.duplicateMediaGroup({
        token,
        name: `${mediaGroup.name} - Cópia`,
      });
      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
      });
      this.requestMediaGroups();
    } catch (error) {
      Logger.error("error in duplicateMediaGroup", error);
      this.setIsFetchingMediaGroups(false);
    }
  };

  deleteMediaGroup = async (token: string) => {
    this.setIsFetchingMediaGroups(true);
    try {
      const response: any = await this.mobR2APIService.deleteMediaGroup(token);

      this.notificationsStore.addSnackbarNotification({
        message: response.data.message,
        color: "warning",
      });
      this.requestMediaGroups();
    } catch (error) {
      Logger.error("error in deleteMediaGroup", error);
      this.setIsFetchingMediaGroups(false);
    }
  };
}

export default MediaLibraryStore;
