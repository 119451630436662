import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";
import { GridContainer, GridItem } from "../components/Grid";

import PlaylistScheduleStore from "../store/PlaylistScheduleStore/PlaylistScheduleStore";

import PlaylistsStore from "../store/PlaylistsStore/PlaylistsStore";
import LoadingView from "../components/LoadingView/LoadingView";
import PlaylistSchedulesContainer from "./playlistSchedulesPage/PlaylistSchedulesContainer";
import Conditional from "../components/Conditional/Conditional";
import ContractStore from "../store/ContractStore/ContractStore";

interface PlaylistSchedulesPageProps {
  history: any;
  match: any;
}

interface InjectedProps extends PlaylistSchedulesPageProps {
  contractStore: ContractStore;
  playlistScheduleStore: PlaylistScheduleStore;
  playlistsStore: PlaylistsStore;
}

@inject("playlistScheduleStore", "playlistsStore", "contractStore")
@observer
class PlaylistSchedulesPage extends Component<PlaylistSchedulesPageProps> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  componentDidMount() {
    this.injected.playlistScheduleStore.requestPlaylistSchedules();
    this.injected.playlistsStore.getDataList();
    this.injected.contractStore.getContractsList();
  }

  isFetchingStores = () => {
    return (
      this.injected.playlistScheduleStore.isFetching ||
      this.injected.playlistsStore.isFetching ||
      this.injected.contractStore.isFetching
    );
  };

  render() {
    const isFetching = this.isFetchingStores();
    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Empresas</PageTitle>
        <GridContainer>
          <GridItem xs={12} sm={10} md={6}>
            <Conditional when={isFetching}>
              <LoadingView />
            </Conditional>
            <Conditional when={!isFetching}>
              <PlaylistSchedulesContainer />
            </Conditional>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default PlaylistSchedulesPage;
