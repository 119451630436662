export interface ScheduleInterface {
  days_week: string[];
  day_in: string;
  day_out: string;
  time: string[];
}

export const defaultAlwaysOnSchedule: ScheduleInterface = {
  days_week: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
  day_in: "",
  day_out: "",
  time: ["00:00", "23:59"],
};

class PlaylistScheduleModel {
  token?: string;
  companyToken: string;
  playlistToken: string;
  schedule?: ScheduleInterface;
  isActive: boolean;
  order: number;

  constructor({
    companyToken,
    playlistToken,
    schedule = defaultAlwaysOnSchedule,
    isActive = true,
    order = 9999,
    token,
  }: {
    companyToken: string;
    playlistToken: string;
    schedule?: ScheduleInterface;
    isActive?: boolean;
    order?: number;
    token?: string;
  }) {
    this.companyToken = companyToken;
    this.playlistToken = playlistToken;
    this.schedule = schedule;
    this.isActive = isActive;
    this.order = order;
    this.token = token;
  }

  static fromJSON = (data: { [key: string]: any }): PlaylistScheduleModel => {
    if (
      data?.hasOwnProperty("token") &&
      data?.hasOwnProperty("contract_hash") &&
      data?.hasOwnProperty("playlist_token") &&
      data?.hasOwnProperty("is_active") &&
      data?.hasOwnProperty("order")
    ) {
      return new PlaylistScheduleModel({
        token: data.token,
        companyToken: data.contract_hash,
        playlistToken: data.playlist_token,
        schedule: data?.hasOwnProperty("schedule") ? data?.schedule[0] : {},
        isActive: data.is_active,
        order: data.order,
      });
    }

    return null;
  };
}

export default PlaylistScheduleModel;
