// @ts-nocheck
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { inject, observer } from "mobx-react";

import PageTitle from "../components/PageTitle/PageTitle";

import BooleanIcon from "../components/BooleanIcon/BooleanIcon";
import AlertModal from "../components/AlertModal/AlertModal";
import { SimpleCard } from "../components/Card";
import PlaylistConfigurationForm from "../components/AppForm/PlaylistConfigurationForm";
import { GridContainer, GridItem } from "../components/Grid";

import PlaylistsTableContainer from "./playlistsPage/PlaylistsTableContainer";

import PlaylistModel from "../Model/PlaylistModel";

@inject("playlistsStore")
@observer
class PlaylistsPage extends Component {
  state = {
    selectedPlaylist: null,
    didWantToDelete: false,
  };

  componentWillMount() {
    this.props.playlistsStore.getDataList();
  }

  getTableData = (playlistsList: PlaylistModel[]) => {
    return playlistsList.map(
      ({ token, name, mediaGroupTokensList, isRandom, isMuted }) => ({
        name: name,
        files: mediaGroupTokensList.length,
        isRandom: <BooleanIcon isTrue={isRandom} />,
        isMuted: <BooleanIcon isTrue={isMuted} />,
        actions: token,
      })
    );
  };

  didCancelDeleteAction = () => {
    this.setState({
      didWantToDelete: false,
    });
  };

  didDeleteConfiguration = () => {
    this.props.playlistsStore
      .delete(this.state.selectedPlaylist.token)
      .then(() => {
        this.setState({
          didWantToDelete: false,
          selectedPlaylist: null,
        });
      });
  };

  didWantToDelete = () => {
    this.setState({
      didWantToDelete: true,
    });
  };

  didWantToDeleteRow = (token) => () => {
    const selectedPlaylist = this.props.playlistsStore.getWithToken(token);
    this.setState({
      didWantToDelete: true,
      selectedPlaylist,
    });
  };

  didWantToCloneRow = (token) => () => {
    this.props.playlistsStore.duplicate(token);
  };

  didWantToEditRow = (token) => () => {
    const { history, match } = this.props;
    history.push(`${match.path}/editar/${token}`);
  };

  didWantToAddPlaylist = () => {
    const { history, match } = this.props;
    history.push(`${match.path}/adicionar`);
  };

  renderForm = (props) => {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10} lg={8}>
          <SimpleCard cardStyle={{ marginBottom: 240 }}>
            <PlaylistConfigurationForm {...props} />
          </SimpleCard>
        </GridItem>
      </GridContainer>
    );
  };

  render() {
    const { playlistsStore } = this.props;
    const {
      filteredDataList: playlistsList,
      dataList,
      searchString,
      setSearchString,
      isFetching,
    } = playlistsStore;
    const { didWantToDelete } = this.state;

    const pathUrl = this.props.match.path;

    return (
      <div style={{ padding: "0 16px" }}>
        <PageTitle>Playlists</PageTitle>

        {didWantToDelete && (
          <AlertModal
            warning
            title="Confirmar esta ação"
            onConfirm={this.didDeleteConfiguration}
            onCancel={this.didCancelDeleteAction}
            confirmButtonLabel="Apagar"
            cancelButtonLabel="Cancelar"
            showCancel
          >
            <i className="fas fa-exclamation-triangle" /> Esta ação não poderá
            ser desfeita.
          </AlertModal>
        )}
        <Switch>
          <Route
            path={`${pathUrl}/adicionar`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={`${pathUrl}/editar/:playlistToken`}
            render={(props) => this.renderForm(props)}
          />
          <Route
            path={pathUrl}
            render={(props) => (
              <PlaylistsTableContainer
                {...props}
                dataList={dataList}
                searchString={searchString}
                setSearchString={setSearchString}
                data={this.getTableData(playlistsList)}
                isFetching={isFetching}
                didWantToAddPlaylist={this.didWantToAddPlaylist}
                didWantToDeleteRow={this.didWantToDeleteRow}
                didWantToCloneRow={this.didWantToCloneRow}
                didWantToEditRow={this.didWantToEditRow}
              />
            )}
          />
        </Switch>
      </div>
    );
  }
}

export default PlaylistsPage;
