import "mobx-react-lite/batchingForReactDom"; // MobX fix for React described here: https://github.com/mobxjs/mobx-react-lite/#observer-batching
import React from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import packageJson from "../package.json";
import { configureLogger } from "simplyas-js-logger";

// Main CSS
import "./assets/scss/app-style.css";

import MainStore from "./store/MainStore/MainStore";
import MainServices from "./services/MainServices";

import App from "./App";

import { isLocalhost, isProductionServer } from "./utils/EnvUtils";

configure({ enforceActions: isLocalhost ? "observed" : "never" });

configureLogger({
  shouldDebug:
    window.location.href.includes("debug=true") || !isProductionServer,
});

const services = new MainServices();
const mainStore = new MainStore(services);
const APP_VERSION = packageJson.version;

ReactDOM.render(
  <Provider {...mainStore} {...services} appVersion={APP_VERSION}>
    <App />
  </Provider>,
  document.getElementById("app-root")
);

// if ((module as any).hot) {
//   (module as any).hot.accept();
// }
