// @ts-nocheck
import moment from "moment";
import "moment/locale/pt-br";

const datesAreSame = (date1, date2) => {
  return date1.isSame(date2, "day");
};

export const stringDateFromTimestamp = (timestampDate, format = "DD/MM/YY") => {
  const dateFromTimestamp = new Date(timestampDate);

  return moment(dateFromTimestamp).format(format);
};

const stringDateFromUnixDate = (unixDate, format = "DD/MM/YY") => {
  return moment.unix(unixDate).format(format);
};

const momentDateFromUnixDate = (unixDate) => {
  return moment.unix(unixDate);
};

const getActiveDaysFromArray = (daysWeekArray) => {
  let sun = false;
  let mon = false;
  let tue = false;
  let wed = false;
  let thu = false;
  let fri = false;
  let sat = false;

  if (daysWeekArray) {
    daysWeekArray.forEach((day) => {
      if (day === "dom") sun = true;
      if (day === "seg") mon = true;
      if (day === "ter") tue = true;
      if (day === "qua") wed = true;
      if (day === "qui") thu = true;
      if (day === "sex") fri = true;
      if (day === "sab") sat = true;
    });
  }

  return {
    sun,
    mon,
    tue,
    wed,
    thu,
    fri,
    sat,
  };
};

const dayOfTheWeekOfDate = (date) => {
  const result = date.format("ddd").toLowerCase();
  if (result === "sáb") return "sab";

  return result;
};

const dayOfTheWeek = () => {
  return dayOfTheWeekOfDate(getNowMoment());
};

export const getNowMoment = () => moment();

const getYesterdayMoment = () => {
  return getNowMoment().add(-1, "days");
};

const getTomorrowMoment = () => {
  return getNowMoment().add(1, "days");
};

export const getPreviousMonthForDay = (day) => {
  return startOfTheDayMoment({ day }).subtract(1, "months");
};

export const getNextMonthDateForDay = (day) => {
  return endOfTheDayMoment({ day }).add(1, "months");
};

export const getStartOfWeekForMoment = (dayMoment) => {
  return dayMoment.startOf("week");
};

export const getEndOfWeekForMoment = (dayMoment) => {
  return dayMoment.endOf("week");
};

export const startOfCurrentWeekMoment = () => {
  return getStartOfWeekForMoment(startOfTheDayMoment());
};

export const endOfCurrentWeekMoment = () => {
  return getEndOfWeekForMoment(startOfTheDayMoment());
};

export const getStartOfMonthForMoment = (dayMoment) => {
  return dayMoment.startOf("month");
};

export const getEndOfMonthForMoment = (dayMoment) => {
  return dayMoment.endOf("month");
};

export const startOfCurrentMonthMoment = () => {
  return getStartOfMonthForMoment(startOfTheDayMoment());
};

export const endOfCurrentMonthMoment = () => {
  return getEndOfMonthForMoment(startOfTheDayMoment());
};

const addDaysToDate = (date, daysToAdd) => {
  return date.add(daysToAdd, "days");
};

const subtrackDaysToDate = (date, daysToSubtrack) => {
  return date.add(-daysToSubtrack, "days");
};

const timeOfTheDayAsNumbers = () => {
  const hourAndMinutesString = getNowMoment().format("HHmm");
  return parseInt(hourAndMinutesString, 10);
};

const dateIsBetweenRangeDatesUnix = (date, startDateUnix, endDateUnix) => {
  const startDate = momentDateFromUnixDate(startDateUnix);
  const endDate = momentDateFromUnixDate(endDateUnix);

  return (
    date.isSameOrAfter(startDate, "day") && date.isSameOrBefore(endDate, "day")
  );
};

const todayIsBetweenRangeDatesUnix = (startDateUnix, endDateUnix) => {
  return dateIsBetweenRangeDatesUnix(
    getNowMoment(),
    startDateUnix,
    endDateUnix
  );
};

const secondsToNextFullMinute = () => {
  const secondsNow = parseInt(getNowMoment().format("ss"), 10);

  return 60 - secondsNow;
};

export const formatWordPressDate = (wordPressDate, format) => {
  return moment(wordPressDate).format(format);
};

export const getNowTimestamp = () => {
  return getNowMoment().unix();
};

export const getOneMonthAgoMoment = () => {
  return getNowMoment().subtract(30, "days");
};

export const getOneMonthAgoTimestamp = () => this.getOneMonthAgoMoment().unix();

export const startOfTheDayMoment = (customConfig: any = {}) => {
  return moment({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
    ...customConfig,
  });
};

export const endOfTheDayMoment = (customConfig: any = {}) => {
  return moment({
    hour: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
    ...customConfig,
  });
};

export const secondsToMinutesAndHours = (timeInSeconds) => {
  let days = Math.floor(timeInSeconds / 86400);
  let hours = Math.floor((timeInSeconds % 86400) / 3600);
  let minutes = Math.floor((timeInSeconds % 3600) / 60);
  let seconds = Math.floor(timeInSeconds) % 60;

  const addZeroIfLessThanTen = (value) => (value < 10 ? "0" : "");
  const formatTime = (value) => `${addZeroIfLessThanTen(value)}${value}`;

  const formattedDays = days > 0 ? `${days}d ` : "";
  const formattedHours = hours > 0 ? `${formatTime(hours)}h ` : "";
  const formattedMinutes = minutes > 0 ? `${formatTime(minutes)}m ` : "";
  const formattedSeconds = formatTime(seconds);

  return `${formattedDays}${formattedHours}${formattedMinutes}${formattedSeconds}s`;
};

export {
  stringDateFromUnixDate,
  momentDateFromUnixDate,
  getActiveDaysFromArray,
  getYesterdayMoment,
  getTomorrowMoment,
  addDaysToDate,
  subtrackDaysToDate,
  dayOfTheWeek,
  dayOfTheWeekOfDate,
  timeOfTheDayAsNumbers,
  dateIsBetweenRangeDatesUnix,
  todayIsBetweenRangeDatesUnix,
  secondsToNextFullMinute,
  datesAreSame,
};
