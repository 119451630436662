import { observable, computed, action } from "mobx";

import UserModel, {
  EPermissionCategory,
  EPermissionType,
} from "../../Model/UserModel";
import MobR2APIService from "../../services/MobR2APIService";
import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";

const kCurrentUserKey = "k_mobr2_current_user_token";

class AuthStore {
  notificationsStore: NotificationsStore;
  internalEventsStore: InternalEventsStore;
  mobR2APIService: MobR2APIService;

  @observable
  isFetching: boolean = false;
  @observable
  currentUser: UserModel | null = null;

  constructor(
    notificationsStore: NotificationsStore,
    internalEventsStore: InternalEventsStore,
    mobR2APIService: MobR2APIService
  ) {
    this.notificationsStore = notificationsStore;
    this.internalEventsStore = internalEventsStore;
    this.mobR2APIService = mobR2APIService;

    const savedUserToken = window.localStorage.getItem(kCurrentUserKey);

    if (savedUserToken) {
      this.tryToLoginWithSavedUserToken(savedUserToken);
    }
  }

  @action
  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };
  @action
  setCurrentUser = (currentUser: UserModel | null) => {
    this.currentUser = currentUser;
    this.setIsFetching(false);
  };

  @computed
  get isAuthenticated() {
    return this.currentUser !== null;
  }

  @computed
  get currentUserToken() {
    return this.isAuthenticated ? this.currentUser.token : null;
  }

  hasAccessOnlyTo = (category: EPermissionCategory, type: EPermissionType) => {
    if (!this.isAuthenticated) return false;
    return this.currentUser?.hasAccessOnlyTo(category, type);
  };

  hasAccessTo = (category: EPermissionCategory, type: EPermissionType) => {
    if (!this.isAuthenticated) return false;
    return this.currentUser?.hasAccessTo(category, type);
  };

  tryToLoginWithSavedUserToken = async (userToken: string) => {
    this.setIsFetching(true);
    const user = await this.getCurrentUserProfile(userToken);
    this.setCurrentUser(user);
  };

  didLogin = (user: UserModel) => {
    window.localStorage.setItem(kCurrentUserKey, user.token);
    this.setCurrentUser(user);
  };

  getCurrentUserProfile = async (userToken: string) => {
    try {
      const response: any = await this.mobR2APIService.requestUser(userToken);

      const user = new UserModel(response.data);
      return user;
    } catch (error) {
      console.error("error in requestUser", error);
      // Clear saved user token
      window.localStorage.removeItem(kCurrentUserKey);
      return null;
    }
  };

  submitLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const response: any = await this.mobR2APIService.submitLogin({
        email,
        password,
      });
      if (response.status === 200) {
        const user = await this.getCurrentUserProfile(response.data.user_token);
        this.didLogin(user);
        return Promise.resolve();
      }
      return Promise.reject();
    } catch (error) {
      console.error("error in submitLogin", error);
      return Promise.reject();
    }
  };

  logout = (force = false) => {
    // Clear saved user token
    window.localStorage.removeItem(kCurrentUserKey);

    if (force) {
      window.location.href = "/login";
    }

    if (this.isAuthenticated) {
      this.internalEventsStore.notify(EInternalEvents.didLogout);
      this.setCurrentUser(null);
    }
  };

  recoverPassword = (email: string) => {
    return Promise.reject();
  };
}

export default AuthStore;
