import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";

import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  grayColor,
} from "../../assets/jss/app-js-styles";

const useStyles = makeStyles({
  badge: {
    borderRadius: 12,
    padding: "5px 12px",
    textTransform: "uppercase",
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1,
    color: "#fff",
    textAlign: "center",
    verticalAlign: "baseline",
    display: "inline-block",
  },
  primary: {
    backgroundColor: primaryColor,
  },
  warning: {
    backgroundColor: warningColor,
  },
  danger: {
    backgroundColor: dangerColor,
  },
  success: {
    backgroundColor: successColor,
  },
  info: {
    backgroundColor: infoColor,
  },
  gray: {
    backgroundColor: grayColor,
  },
  default: {
    backgroundColor: "transparent",
    border: `1px solid ${grayColor}`,
    color: grayColor,
  },
});

interface IBadgeProps {
  color?: string;
  style?: object;
}

const Badge: React.FunctionComponent<IBadgeProps> = ({
  color,
  children,
  style,
}) => {
  const classes = useStyles();
  return (
    <span className={classes.badge + " " + classes[color]} style={style}>
      {children}
    </span>
  );
};

Badge.defaultProps = {
  color: "default",
};

Badge.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "gray",
    "default",
  ]),
};

export default Badge;
