// @ts-nocheck
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import PageTitle from "../components/PageTitle/PageTitle";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import { GridContainer, GridItem } from "../components/Grid";
import { Button } from "../components/Button";
import { InputText } from "../components/Form";
import Validate, { ValidationType } from "../components/Form/Validate";
import { Card, CardBody, CardHeader, CardFooter } from "../components/Card";

// Style
import { container, cardTitle } from "../assets/jss/app-js-styles";
const styles = {
  container: {
    ...container,
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
  },
  cardTitle: {
    ...cardTitle,
    color: "#FFFFFF",
  },
  content: {
    minHeight: "calc(100vh - 80px)",
    position: "relative",
    zIndex: "4",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: "#555",
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  logoContainer: {
    textAlign: "center",
  },
};

@inject("authStore", "settingsStore")
@observer
class LoginPage extends Component {
  constructor(props) {
    super(props);

    // we use this to make the card to appear after the page has been rendered
    this.state = {
      email: "",
      password: "",
      cardAnimaton: "cardHidden",
      viewMode: "login",
      isWaitingForm: false,
      formWasSubmit: false,
      recoverSuccess: false,
      isValid: {
        email: false,
        password: false,
      },
    };
  }
  componentDidMount() {
    this.doAnimation();
  }

  doAnimation = () => {
    setTimeout(() => {
      this.setState({
        cardAnimaton: "",
      });
    }, 400);
  };

  changeViewMode = (viewMode) => {
    this.setState(
      {
        cardAnimaton: "cardHidden",
      },
      () => {
        setTimeout(() => {
          this.setState({
            viewMode,
            cardAnimaton: "",
          });
        }, 400);
      }
    );
  };

  onChangeEmail = ({ target }) => {
    const email = target.value;
    this.setState({
      email,
      isValid: {
        ...this.state.isValid,
        email: Validate(email, ValidationType.EMAIL),
      },
    });
  };

  onChangePassword = ({ target }) => {
    const password = target.value;
    this.setState({
      password,
      isValid: {
        ...this.state.isValid,
        password: Validate(password, ValidationType.REQUIRED),
      },
    });
  };

  canSubmitForm = () => {
    const { viewMode, isValid } = this.state;
    if (viewMode === "login") {
      return isValid.email && isValid.password;
    }

    return isValid.email;
  };

  didSubmitForm = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!this.canSubmitForm()) {
      return false;
    }

    this.setState(
      {
        formWasSubmit: true,
        isWaitingForm: true,
      },
      () => {
        if (this.state.viewMode === "login") {
          const { email, password } = this.state;

          this.props.authStore
            .submitLogin({
              email,
              password,
            })
            .catch((_) => {
              this.setState({ isWaitingForm: false });
            });
        } else {
          this.props.authStore
            .recoverPassword({
              email: this.state.email,
            })
            .catch((_) => {
              this.setState({ isWaitingForm: false });
            });
        }
      }
    );
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      isValid,
      viewMode,
      isWaitingForm,
      formWasSubmit,
    } = this.state;

    return (
      <div className={classes.content}>
        <PageTitle>Login</PageTitle>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.didSubmitForm}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader style={{ paddingTop: 20, paddingBottom: 0 }}>
                    <GridContainer>
                      <GridItem xs={12} className={classes.logoContainer}>
                        <img
                          src={this.props.settingsStore.logoImage}
                          style={{ maxWidth: 60 }}
                          alt="Logo"
                        />
                      </GridItem>
                    </GridContainer>
                  </CardHeader>
                  <CardBody>
                    {viewMode === "recoverPassword" &&
                      this.state.recoverSuccess && (
                        <p style={{ textAlign: "center" }}>
                          Um link foi enviado para o e-mail informado!
                        </p>
                      )}
                    {(viewMode === "login" || !this.state.recoverSuccess) && (
                      <InputText
                        label="E-mail"
                        name="email"
                        value={email}
                        autoFocus
                        inputProps={{
                          type: "email",
                        }}
                        rightIcon={
                          <InputAdornment position="end">
                            <i
                              className={`fas fa-envelope ${classes.inputAdornmentIcon}`}
                            />
                          </InputAdornment>
                        }
                        hasError={formWasSubmit && !isValid.email}
                        hasSuccess={formWasSubmit && isValid.email}
                        onChange={this.onChangeEmail}
                      />
                    )}
                    {viewMode === "login" && (
                      <InputText
                        label="Senha"
                        name="password"
                        value={password}
                        inputProps={{
                          type: "password",
                          autoComplete: "off",
                        }}
                        rightIcon={
                          <InputAdornment position="end">
                            <i
                              className={`fas fa-key ${classes.inputAdornmentIcon}`}
                            />
                          </InputAdornment>
                        }
                        hasError={formWasSubmit && !isValid.password}
                        hasSuccess={formWasSubmit && isValid.password}
                        onChange={this.onChangePassword}
                      />
                    )}
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {viewMode === "login" && (
                      <GridContainer>
                        <GridItem xs={12}>
                          <Button
                            color="primary"
                            type="submit"
                            block
                            disabled={!isValid.email || !isValid.password}
                            isLoading={isWaitingForm}
                          >
                            Entrar <i className="fas fa-sign-in-alt" />
                          </Button>
                        </GridItem>
                      </GridContainer>
                    )}
                    {viewMode === "recoverPassword" && (
                      <GridContainer>
                        {!this.state.recoverSuccess && (
                          <GridItem xs={12}>
                            <Button
                              color="warning"
                              type="submit"
                              block
                              disabled={!isValid.email}
                              isLoading={isWaitingForm}
                            >
                              Recuperar senha <i className="fas fa-undo" />
                            </Button>
                          </GridItem>
                        )}
                        {!isWaitingForm && (
                          <GridItem xs={12}>
                            <Button
                              color="primary"
                              outline
                              block
                              onClick={() => this.changeViewMode("login")}
                            >
                              Voltar ao Login
                            </Button>
                          </GridItem>
                        )}
                      </GridContainer>
                    )}
                  </CardFooter>
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoginPage);
