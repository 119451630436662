import React from "react";
import { observer } from "mobx-react";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

import { Button } from "../../components/Button";
import { GridContainer, GridItem } from "../../components/Grid";
import SearchBar from "../../components/SearchBar/SearchBar";
import { SimpleCard } from "../../components/Card";

import ScheduleRuleForCompany from "./ScheduleRuleForCompany";

import PlaylistScheduleModel from "../../Model/PlaylistScheduleModel";
import ContractModel from "../../Model/ContractModel";

//Style
const styles = createStyles({
  addButton: {
    marginBottom: 20,
  },
  nothingFound: {
    textAlign: "center",
    fontWeight: "bold",
  },
});

interface PlaylistSchedulesPageViewProps {
  classes?: any;
  companiesList: ContractModel[];
  playlistSchedulesList: PlaylistScheduleModel[];
  filteredPlaylistSchedulesList: PlaylistScheduleModel[];
  searchString: string;
  setSearchString: (searchString: string) => void;
  getPlaylistName: (token: string) => string;
  didWantToAddSchedule: () => void;
  didWantToEditSchedule: (token: string) => void;
  didReorderSchedules: (reorderedList: string[]) => void;
}

const PlaylistSchedulesPageView: React.FunctionComponent<PlaylistSchedulesPageViewProps> = observer(
  ({
    classes,
    playlistSchedulesList,
    filteredPlaylistSchedulesList,
    companiesList,
    searchString,
    setSearchString,
    getPlaylistName,
    didWantToAddSchedule,
    didWantToEditSchedule,
    didReorderSchedules,
  }) => (
    <GridContainer>
      {playlistSchedulesList.length > 5 && (
        <GridItem xs={12}>
          <SimpleCard cardStyle={{ marginTop: 0 }}>
            <SearchBar value={searchString} onChange={setSearchString} />
          </SimpleCard>
        </GridItem>
      )}

      <GridItem xs={12}>
        <Button
          color="info"
          small
          block
          className={classes.addButton}
          onClick={didWantToAddSchedule}
        >
          Adicionar <i className="fas fa-plus-circle" />
        </Button>
        {companiesList.length > 0 ? (
          companiesList.map(({ hash, name }) => (
            <ScheduleRuleForCompany
              key={hash}
              companyName={name}
              companyToken={hash}
              getPlaylistName={getPlaylistName}
              didWantToEditSchedule={didWantToEditSchedule}
              playlistSchedulesList={filteredPlaylistSchedulesList.filter(
                ({ companyToken }) => hash === companyToken
              )}
              didReorderSchedules={didReorderSchedules}
            />
          ))
        ) : (
          <p className={classes.nothingFound}>
            <i className="fas fa-exclamation-triangle" /> Nada encontrado
          </p>
        )}
      </GridItem>
    </GridContainer>
  )
);

export default withStyles(styles)(PlaylistSchedulesPageView);
