import React, { Component, Fragment } from "react";
import { inject, observer } from "mobx-react";

import ResourceWhatWhereWhen from "./ScheduleWhatWhereWhen";
import Modal from "../../components/Modal/Modal";

import PlaylistSchedulesPageView from "./PlaylistSchedulesPageView";

import PlaylistsStore from "../../store/PlaylistsStore/PlaylistsStore";
import NotificationsStore from "../../store/NotificationsStore/NotificationsStore";
import PlaylistScheduleStore from "../../store/PlaylistScheduleStore/PlaylistScheduleStore";
import ContractStore from "../../store/ContractStore/ContractStore";

interface IPlaylistSchedulesContainerProps {}

interface InjectedProps extends IPlaylistSchedulesContainerProps {
  playlistsStore: PlaylistsStore;
  playlistScheduleStore: PlaylistScheduleStore;
  notificationsStore: NotificationsStore;
  contractStore: ContractStore;
}

interface IPlaylistSchedulesContainerState {
  isOpenModal: boolean;
  selectedToken: string | null;
}

@inject(
  "playlistsStore",
  "playlistScheduleStore",
  "notificationsStore",
  "contractStore"
)
@observer
class PlaylistSchedulesContainer extends Component<
  IPlaylistSchedulesContainerProps,
  IPlaylistSchedulesContainerState
> {
  get injected(): InjectedProps {
    return this.props as InjectedProps;
  }

  _isMounted: boolean = false;

  constructor(props) {
    super(props);

    this.state = {
      isOpenModal: false,
      selectedToken: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  didWantToAddSchedule = () => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
    });
  };

  didWantToEditSchedule = (token: string) => {
    if (!this._isMounted) return;
    this.setState({
      isOpenModal: true,
      selectedToken: token,
    });
  };

  didCloseModal = () => {
    if (!this._isMounted) return;
    this.setState(
      {
        isOpenModal: false,
      },
      () => {
        setTimeout(() => {
          if (!this._isMounted) return;
          this.setState({
            selectedToken: null,
          });
        }, 200);
      }
    );
  };

  didSaveResourceForm = () => {
    this.didCloseModal();
  };

  didCancelResourceForm = () => {
    this.didCloseModal();
  };

  didDeleteResourceForm = () => {
    this.didCloseModal();
  };

  didReorderSchedules = (reorderedList: string[]) => {
    this.injected.playlistScheduleStore.reorder(reorderedList);
  };

  getPlaylistName = (token: string) =>
    this.injected.playlistsStore.getWithToken(token)?.name;

  getModalBody = () => {
    const {
      playlistsStore,
      playlistScheduleStore,
      contractStore,
    } = this.injected;
    const { selectedToken } = this.state;

    const selectedPlaylistSchedule = playlistScheduleStore.getWithToken(
      selectedToken
    );

    const what = selectedPlaylistSchedule
      ? playlistsStore.getWithToken(selectedPlaylistSchedule.playlistToken)
      : null;

    const where = selectedPlaylistSchedule
      ? contractStore.getWithToken(selectedPlaylistSchedule.companyToken)
      : null;

    return (
      <ResourceWhatWhereWhen
        playlistSchedule={selectedPlaylistSchedule}
        what={what}
        whatList={playlistsStore.dataList}
        where={where}
        whereList={contractStore.contractsList}
        when={selectedPlaylistSchedule?.schedule || null}
        isActive={
          selectedPlaylistSchedule ? selectedPlaylistSchedule.isActive : true
        }
        didSubmit={this.didCloseModal}
        didDelete={this.didCloseModal}
      />
    );
  };

  render() {
    const { isOpenModal } = this.state;
    const {
      playlistSchedulesList,
      filteredPlaylistSchedulesList,
      searchString,
      setSearchString,
    } = this.injected.playlistScheduleStore;

    return (
      <Fragment>
        <Modal
          isOpen={isOpenModal}
          didCloseModal={this.didCloseModal}
          maxWidth="md"
        >
          {this.getModalBody()}
        </Modal>
        <PlaylistSchedulesPageView
          playlistSchedulesList={playlistSchedulesList}
          companiesList={this.injected.contractStore.filteredContractsList}
          filteredPlaylistSchedulesList={filteredPlaylistSchedulesList}
          searchString={searchString}
          setSearchString={setSearchString}
          getPlaylistName={this.getPlaylistName}
          didWantToAddSchedule={this.didWantToAddSchedule}
          didWantToEditSchedule={this.didWantToEditSchedule}
          didReorderSchedules={this.didReorderSchedules}
        />
      </Fragment>
    );
  }
}

export default PlaylistSchedulesContainer;
