export const USER_DEFAULT_PERMISSIONS: {
  [key in EPermissionCategory]: TCategoryRule;
} = {
  companies: {
    read: true,
    edit: true,
    delete: true,
  },
  files: {
    read: true,
    edit: true,
    delete: true,
  },
  media_groups: {
    read: true,
    edit: true,
    delete: true,
  },
  playlist_schedules: {
    read: true,
    edit: true,
    delete: true,
  },
  playlists: {
    read: true,
    edit: true,
    delete: true,
  },
};

export enum EPermissionCategory {
  companies = "companies",
  files = "files",
  mediaGroups = "media_groups",
  playlistSchedules = "playlist_schedules",
  playlists = "playlists",
}

export enum EPermissionType {
  read = "read",
  edit = "edit",
  delete = "delete",
}

export type TCategoryRule = { [key in EPermissionType]: boolean };

export interface IUserModel {
  token?: string;
  name: string;
  email: string;
  permissions: {
    companies: TCategoryRule;
    files: TCategoryRule;
    media_groups: TCategoryRule;
    playlist_schedules: TCategoryRule;
    playlists: TCategoryRule;
  };
  isActive: boolean;
  isAdmin: boolean;
}

class UserModel implements IUserModel {
  token?: string;
  name: string;
  email: string;
  permissions: {
    companies: TCategoryRule;
    files: TCategoryRule;
    media_groups: TCategoryRule;
    playlist_schedules: TCategoryRule;
    playlists: TCategoryRule;
  };
  isActive: boolean;
  isAdmin: boolean;

  constructor(user) {
    this.token = user.user_token;
    this.name = user.name;
    this.email = user.email;
    this.permissions = USER_DEFAULT_PERMISSIONS;
    this.isActive = user.is_active;
    this.isAdmin = user.is_admin;
  }

  get isSimplyAsEmployee(): boolean {
    return this.email.includes("@simplyas.com");
  }

  hasAccessOnlyTo = (category: EPermissionCategory, type: EPermissionType) => {
    return this.permissions[category][type];
  };

  hasAccessTo = (category: EPermissionCategory, type: EPermissionType) => {
    return this.isAdmin || this.hasAccessOnlyTo(category, type);
  };
}

export default UserModel;
