import React from "react";

import DateUnixString from "../Date/DateUnixString";
import DaysWeekString from "../Date/DaysWeekString";

// Utils
import { startOfTheDayMoment } from "../../utils/DateUtils";
import { ScheduleInterface } from "../../Model/PlaylistScheduleModel";

const ScheduleText: React.FunctionComponent<{
  schedule: ScheduleInterface;
  style?: any;
}> = ({ schedule, style = {} }) => {
  if (!schedule) return null;

  const timeFormat = "HH:mm";
  const [timeStartHour, timeStartMinute] = schedule.time[0].split(":");
  const [timeEndHour, timeEndMinute] = schedule.time[1].split(":");
  const startTime = startOfTheDayMoment({
    hour: timeStartHour,
    minute: timeStartMinute,
  }).format(timeFormat);
  const endTime = startOfTheDayMoment({
    hour: timeEndHour,
    minute: timeEndMinute,
  }).format(timeFormat);

  return (
    <p
      style={{
        margin: 0,
        ...style,
      }}
    >
      {schedule["day_in"] ? (
        <span>
          <i className="fas fa-calendar" />{" "}
          <DateUnixString unixDate={schedule.day_in} /> à{" "}
          <DateUnixString unixDate={schedule.day_out} />
        </span>
      ) : (
        <DaysWeekString daysWeek={schedule.days_week} />
      )}{" "}
      <span>
        {startTime}h às {endTime}h
      </span>
    </p>
  );
};

export default ScheduleText;
