import { action, observable, computed } from "mobx";

import PlaylistScheduleModel from "../../Model/PlaylistScheduleModel";
import InternalEventsStore, {
  EInternalEvents,
} from "../InternalEventsStore/InternalEventsStore";

// Util
import { didSearchList } from "../../utils/Utils";
import { dynamicSortByProperty } from "../../utils/SortUtils/SortUtils";

export type RequestPlaylistSchedulesFn = (
  token?: string
) => Promise<PlaylistScheduleModel[]>;
export type SubmitPlaylistScheduleFn = (
  playlistSchedule: PlaylistScheduleModel
) => Promise<void>;
export type UpdatePlaylistScheduleFn = (
  playlistSchedule: PlaylistScheduleModel
) => Promise<void>;
export type DeletePlaylistScheduleFn = (token: string) => Promise<void>;
export type ReorderPlaylistSchedulesFn = (
  tokensList: string[]
) => Promise<void>;
export type GetCompanyNameFn = (token: string) => string;
export type GetPlaylistNameFn = (token: string) => string;

export interface PlaylistScheduleStoreFunctions {
  request: RequestPlaylistSchedulesFn;
  submit: SubmitPlaylistScheduleFn;
  update: UpdatePlaylistScheduleFn;
  delete: DeletePlaylistScheduleFn;
  reorder: ReorderPlaylistSchedulesFn;
  getCompanyName: GetCompanyNameFn;
  getPlaylistName: GetPlaylistNameFn;
}
interface PlaylistScheduleStoreProperties
  extends PlaylistScheduleStoreFunctions {
  internalEventsStore: InternalEventsStore;
  isFetching: boolean;
  searchString: string;
  playlistSchedulesList: PlaylistScheduleModel[];
}

class PlaylistScheduleStore implements PlaylistScheduleStoreProperties {
  internalEventsStore: InternalEventsStore;

  @observable isFetching = false;
  @observable searchString = "";
  @observable playlistSchedulesList: PlaylistScheduleModel[] = [];

  request: RequestPlaylistSchedulesFn;
  submit: SubmitPlaylistScheduleFn;
  update: UpdatePlaylistScheduleFn;
  delete: DeletePlaylistScheduleFn;
  reorder: ReorderPlaylistSchedulesFn;
  getCompanyName: GetCompanyNameFn;
  getPlaylistName: GetPlaylistNameFn;

  constructor(
    internalEventsStore: InternalEventsStore,
    {
      request,
      submit,
      update,
      delete: deleteFn,
      reorder,
      getCompanyName,
      getPlaylistName,
    }: PlaylistScheduleStoreFunctions
  ) {
    this.request = request;
    this.submit = submit;
    this.update = update;
    this.delete = deleteFn;
    this.reorder = reorder;
    this.getCompanyName = getCompanyName;
    this.getPlaylistName = getPlaylistName;

    internalEventsStore.subscribeTo({
      eventKey: EInternalEvents.didLogout,
      observer: this,
      callback: this.clearStore,
    });
  }

  @action
  private setIsFetching = (newValue: boolean) => {
    this.isFetching = newValue;
  };

  @action
  setSearchString = (newValue: string) => {
    this.searchString = newValue;
  };

  @action
  private setPlaylistSchedulesList = (newList: PlaylistScheduleModel[]) => {
    this.playlistSchedulesList = newList;
  };

  @computed
  get filteredPlaylistSchedulesList(): PlaylistScheduleModel[] {
    if (this.searchString.length > 0) {
      return didSearchList(
        this.searchString,
        this.playlistSchedulesList,
        (
          { companyToken, playlistToken }: PlaylistScheduleModel,
          textIncludesSearchString
        ) => {
          const companyName = this.getCompanyName(companyToken);
          const playlistName = this.getPlaylistName(playlistToken);
          const matchCompanyName = textIncludesSearchString(companyName);
          const matchPlaylistName = textIncludesSearchString(playlistName);

          return matchCompanyName || matchPlaylistName;
        }
      );
    }
    return this.playlistSchedulesList;
  }

  private clearStore = () => {
    this.setIsFetching(false);
    this.setPlaylistSchedulesList([]);
    this.setSearchString("");
  };

  requestPlaylistSchedules = async (): Promise<void> => {
    this.setIsFetching(true);

    const playlistSchedulesList = await this.request();
    this.setPlaylistSchedulesList(
      playlistSchedulesList.sort(dynamicSortByProperty("order"))
    );

    this.setIsFetching(false);
  };

  private isValid = (playlistSchedule: PlaylistScheduleModel): boolean => {
    return (
      playlistSchedule.companyToken.length > 2 &&
      playlistSchedule.playlistToken.length > 2
    );
  };

  submitPlaylistSchedule = async (
    playlistSchedule: PlaylistScheduleModel
  ): Promise<void> => {
    if (this.isValid(playlistSchedule)) {
      this.setIsFetching(true);

      await this.submit(playlistSchedule);
      await this.requestPlaylistSchedules();
    }
  };

  updatePlaylistSchedule = async (
    playlistSchedule: PlaylistScheduleModel
  ): Promise<void> => {
    if (this.isValid(playlistSchedule) && playlistSchedule.token?.length > 2) {
      this.setIsFetching(true);

      await this.update(playlistSchedule);
      await this.requestPlaylistSchedules();
    }
  };

  deletePlaylistSchedule = async (token: string): Promise<void> => {
    if (token?.length > 1) {
      this.setIsFetching(true);

      await this.delete(token);
      await this.requestPlaylistSchedules();
    }
  };

  getWithToken = (token: string): PlaylistScheduleModel | null => {
    if (this.playlistSchedulesList.length > 0) {
      return this.playlistSchedulesList.find(
        (playlist) => playlist.token === token
      );
    }
    return null;
  };
}

export default PlaylistScheduleStore;
