// @ts-nocheck
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  FormControl,
  InputLabel,
  Input,
  FormHelperText
} from "@material-ui/core";

// Icons
import { CheckIcon, ClearIcon } from "../Icon/MaterialIcon";

// Style
import customInputStyle from "../../assets/jss/js-styles/components/customInputStyle";

const InputText = ({
  classes,
  value,
  name,
  label,
  placeholder,
  labelProps,
  inputProps,
  formControlProps,
  formHelperTextProps,
  fullWidth = true,
  disabled,
  isRequired,
  autoFocus,
  helpText,
  hasError,
  hasSuccess,
  onChange,
  multiline,
  minLength,
  maxLength,
  leftIcon,
  rightIcon
}) => {
  const labelClasses = classnames({
    [" " + classes.labelRootError]: hasError,
    [" " + classes.labelRootSuccess]: hasSuccess && !hasError
  });
  const underlineClasses = classnames({
    [classes.underlineError]: hasError,
    [classes.underlineSuccess]: hasSuccess && !hasError,
    [classes.underline]: true
    // [classes.whiteUnderline]: white
  });
  const inputClasses = classnames({
    [classes.input]: true
    // [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classnames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  const feedbackClasses = classes.feedback;
  const helperTextId = helpText && name ? `${name}-helper-text` : undefined;

  const endAdornment = hasError ? (
    <ClearIcon className={feedbackClasses + " " + classes.labelRootError} />
  ) : hasSuccess ? (
    <CheckIcon className={feedbackClasses + " " + classes.labelRootSuccess} />
  ) : (
    rightIcon
  );

  return (
    <FormControl
      aria-describedby={helperTextId}
      {...formControlProps}
      fullWidth={fullWidth}
      disabled={disabled}
      className={formControlClasses}
    >
      {label !== undefined && (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={name}
          {...labelProps}
        >
          {label}
        </InputLabel>
      )}
      <Input
        classes={{
          input: inputClasses,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={name}
        name={name}
        placeholder={placeholder}
        required={isRequired}
        autoFocus={autoFocus}
        value={value}
        onChange={onChange}
        multiline={multiline}
        inputProps={{
          ...inputProps,
          minLength,
          maxLength
        }}
        disabled={disabled}
        startAdornment={leftIcon}
        endAdornment={endAdornment}
      />
      {helpText && (
        <FormHelperText id={helperTextId} {...formHelperTextProps}>
          <i className="fa fa-info-circle" /> {helpText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

InputText.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  formHelperTextProps: PropTypes.object,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  isRequired: PropTypes.bool,
  autoFocus: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  hasSuccess: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node
};

export default withStyles(customInputStyle)(InputText);
