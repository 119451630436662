import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import clone from "lodash/clone";

import useMediaQuery from "../../hooks/useMediaQuery";

// Components
import { GridContainer, GridItem } from "../Grid";
import DateConfigDateInOut from "./scheduleForm/DateConfigDateInOut";
import { ButtonGroup, DatePicker, InputSwitch } from "../Form";
import { SimpleCard } from "../Card";

// Utils
import {
  getActiveDaysFromArray,
  startOfTheDayMoment,
  endOfTheDayMoment,
} from "../../utils/DateUtils";
import { ScheduleInterface } from "../../Model/PlaylistScheduleModel";
import { Moment } from "moment";

const dayWeekSwitch = ({ title, key, value, toggleDayWeek }) => (
  <InputSwitch
    name={key}
    label={title}
    isActive={value}
    onChange={toggleDayWeek}
    labelInVertical
  />
);

const DateConfigDaysWeek = ({ daysWeek, toggleDayWeek }) => {
  const fullScreen = useMediaQuery({ down: "sm" });
  const style = {
    display: fullScreen ? "block" : "flex",
    justifyContent: "space-between",
  };

  const { sun, mon, tue, wed, thu, fri, sat } = daysWeek;
  return (
    <div className="row">
      <div className="col-sm-12" style={style}>
        {dayWeekSwitch({
          title: "Domingo",
          key: "sun",
          value: sun,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Segunda",
          key: "mon",
          value: mon,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Terça-feira",
          key: "tue",
          value: tue,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Quarta-feira",
          key: "wed",
          value: wed,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Quinta-feira",
          key: "thu",
          value: thu,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Sexta-feira",
          key: "fri",
          value: fri,
          toggleDayWeek,
        })}
        {dayWeekSwitch({
          title: "Sábado",
          key: "sat",
          value: sat,
          toggleDayWeek,
        })}
      </div>
    </div>
  );
};

interface ScheduleFormProps {
  schedule: ScheduleInterface;
  saveSchedule: (schedule: ScheduleInterface) => void;
}

interface ScheduleFormState {
  dayIn?: string;
  dayOut?: string;
  daysWeek?: {
    sun: boolean;
    mon: boolean;
    tue: boolean;
    wed: boolean;
    thu: boolean;
    fri: boolean;
    sat: boolean;
  };
  timeStart?: Moment;
  timeEnd?: Moment;
  dateConfigType?: "dateInOut" | "daysWeek";
}

@observer
class ScheduleForm extends Component<ScheduleFormProps, ScheduleFormState> {
  constructor(props: ScheduleFormProps) {
    super(props);

    const { schedule } = props;
    const scheduleTime = schedule.time
      ? clone(schedule.time)
      : ["00:00", "23:59"];
    const [timeStartHour, timeStartMinute] = scheduleTime[0].split(":");
    const [timeEndHour, timeEndMinute] = scheduleTime[1].split(":");

    this.state = {
      dayIn: schedule ? schedule.day_in : startOfTheDayMoment().format("X"),
      dayOut: schedule
        ? schedule["day_out"]
        : endOfTheDayMoment()
            .add(7, "day")
            .format("X"),
      daysWeek: this.getDaysWeekState(schedule.days_week),
      timeStart: startOfTheDayMoment({
        hour: timeStartHour,
        minute: timeStartMinute,
      }),
      timeEnd: endOfTheDayMoment({
        hour: timeEndHour,
        minute: timeEndMinute,
      }),
      dateConfigType: schedule["day_in"] ? "dateInOut" : "daysWeek",
    };
  }

  getDaysWeekState = (daysWeekArray: string[]) => {
    if (daysWeekArray) {
      return getActiveDaysFromArray(daysWeekArray);
    }
    return {
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: true,
    };
  };

  onChangeDateConfigType = (
    name: string,
    selectedValue: "daysWeek" | "dateInOut"
  ) => {
    const newState: ScheduleFormState = {
      dateConfigType: selectedValue,
    };
    if (selectedValue === "daysWeek") {
      newState.daysWeek = this.getDaysWeekState([
        "dom",
        "seg",
        "ter",
        "qua",
        "qui",
        "sex",
        "sab",
      ]);
    }

    this.setState(newState, this.didSave);
  };

  onChangeHoursPickerTime = (name, newValue) => {
    this.setState(
      {
        [name]: newValue,
      },
      this.didSave
    );
  };

  toggleDayWeek = (day) => {
    this.setState(
      (prevState) => ({
        daysWeek: {
          ...prevState.daysWeek,
          [day]: !prevState.daysWeek[day],
        },
      }),
      this.didSave
    );
  };

  formatDaysWeekToSave = () => {
    const { daysWeek, dateConfigType } = this.state;
    let result = [];

    if (dateConfigType === "daysWeek") {
      const { sun, mon, tue, wed, thu, fri, sat } = daysWeek;

      if (sun) result.push("dom");
      if (mon) result.push("seg");
      if (tue) result.push("ter");
      if (wed) result.push("qua");
      if (thu) result.push("qui");
      if (fri) result.push("sex");
      if (sat) result.push("sab");
    }

    return result;
  };

  onChangeDateIn = (dayIn: string) => {
    this.setState({ dayIn }, this.didSave);
  };
  onChangeDateOut = (dayOut: string) => {
    this.setState({ dayOut }, this.didSave);
  };

  didSave = () => {
    const { saveSchedule } = this.props;
    const { dayIn, dayOut, timeStart, timeEnd, dateConfigType } = this.state;

    saveSchedule({
      day_in: dateConfigType === "dateInOut" ? dayIn : null,
      day_out: dateConfigType === "dateInOut" ? dayOut : null,
      days_week: this.formatDaysWeekToSave(),
      time: [timeStart.format("HH:mm"), timeEnd.format("HH:mm")],
    });
  };

  render() {
    const {
      dayIn,
      dayOut,
      daysWeek,
      timeStart,
      timeEnd,
      dateConfigType,
    } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12}>
          <ButtonGroup
            name="dateConfigType"
            value={dateConfigType}
            whiteBackgroundColor
            large
            onChange={this.onChangeDateConfigType}
            options={[
              {
                value: "daysWeek",
                label: "Dias da Semana",
              },
              {
                value: "dateInOut",
                label: "Data programada",
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12}>
          {dateConfigType === "daysWeek" ? (
            <DateConfigDaysWeek
              daysWeek={daysWeek}
              toggleDayWeek={this.toggleDayWeek}
            />
          ) : (
            <DateConfigDateInOut
              dayIn={dayIn}
              dayOut={dayOut}
              onChangeDateIn={this.onChangeDateIn}
              onChangeDateOut={this.onChangeDateOut}
            />
          )}
        </GridItem>
        <Fragment>
          <GridItem xs={12} sm={6}>
            <SimpleCard cardStyle={{ marginTop: 0 }}>
              {/* @ts-ignore */}
              <DatePicker
                hideDate
                removeMargin
                fullWidth
                label="Hora início"
                name="timeStart"
                value={timeStart}
                onChange={this.onChangeHoursPickerTime}
              />
            </SimpleCard>
          </GridItem>
          <GridItem xs={12} sm={6}>
            <SimpleCard cardStyle={{ marginTop: 0 }}>
              {/* @ts-ignore */}
              <DatePicker
                hideDate
                removeMargin
                fullWidth
                label="Hora fim"
                name="timeEnd"
                value={timeEnd}
                onChange={this.onChangeHoursPickerTime}
              />
            </SimpleCard>
          </GridItem>
        </Fragment>
      </GridContainer>
    );
  }
}

export default ScheduleForm;
