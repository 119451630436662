// @ts-nocheck
import { observable, action } from "mobx";

import { isProductionServer } from "../../utils/EnvUtils";

import bgLoginPage from "../../assets/img/bg-login-page.jpg";
import logo from "../../assets/img/logo.png";

class SettingsStore {
  @observable mediaFilesPath = "";
  @observable imagesPath = "";
  @observable logoImage = "";
  @observable loginPageBgImage = "";

  @observable sidebarBgImage = "";

  @observable wifiRedirectToDefault =
    window.localStorage.getItem("wifiRedirectToDefault") ||
    "https://google.com";
  @observable wifiTimeDefault =
    window.localStorage.getItem("wifiTimeDefault") || "60";
  @observable exibitionMode =
    window.localStorage.getItem("exibitionMode") || "list";
  @observable mediaDurationToUpload = window.localStorage.getItem(
    "mediaDurationToUpload"
  )
    ? parseInt(window.localStorage.getItem("mediaDurationToUpload"), 10)
    : 10;

  @observable informativeDurationToLiveContent = window.localStorage.getItem(
    "informativeDurationToLiveContent"
  )
    ? parseInt(
        window.localStorage.getItem("informativeDurationToLiveContent"),
        10
      )
    : 120;

  @observable defaultInstagramAccount =
    window.localStorage.getItem("defaultInstagramAccount") || "nasa";

  @observable videoCompressionIsActive = window.localStorage.getItem(
    "videoCompressionIsActive"
  )
    ? window.localStorage.getItem("videoCompressionIsActive") === "true"
    : true;

  @observable whiteLabelCompanyDomain = null;

  constructor() {
    this.setDefaults();
  }

  @action
  setDefaults = () => {
    const hostname = isProductionServer
      ? window.location.hostname
      : "lab-mediaserver.mobr2.com";

    const hostnameList = hostname.split(".");
    this.whiteLabelCompanyDomain = hostnameList[1];

    const hostUrl = isProductionServer
      ? window.location.host
      : "lab-mediaserver.mobr2.com";

    this.mediaFilesPath = `https://${hostUrl}/media`;
    this.imagesPath = `https://${hostUrl}/static/img`;

    this.logoImage = logo;
    this.loginPageBgImage = bgLoginPage;
  };

  @action
  actionChangeWifiRedirectToDefault = (newValue) => {
    this.wifiRedirectToDefault = newValue;
    window.localStorage.setItem("wifiRedirectToDefault", newValue);
  };

  @action
  actionChangeWifiTimeDefault = (newValue) => {
    this.wifiTimeDefault = newValue;
    window.localStorage.setItem("wifiTimeDefault", newValue);
  };

  @action
  actionChangeDefaultInstagramAccount = (newValue) => {
    this.defaultInstagramAccount = newValue;
    window.localStorage.setItem("defaultInstagramAccount", newValue);
  };

  @action
  actionChangeExibitionModeType = (newType) => {
    this.exibitionMode = newType;
    window.localStorage.setItem("exibitionMode", newType);
  };

  @action
  actionChangeMediaDurationToUpload = (newValue) => {
    const value = !isNaN(newValue) ? newValue : 10;
    this.mediaDurationToUpload = value;
    window.localStorage.setItem("mediaDurationToUpload", `${value}`);
  };

  @action
  actionChangeInformativeDurationToLiveContent = (newValue) => {
    this.informativeDurationToLiveContent = newValue;
    window.localStorage.setItem(
      "informativeDurationToLiveContent",
      `${newValue}`
    );
  };

  @action
  actionToggleVideoCompressionIsActive = () => {
    const newStatus = !this.videoCompressionIsActive;
    this.videoCompressionIsActive = newStatus;
    window.localStorage.setItem("videoCompressionIsActive", newStatus);
  };
}

export default SettingsStore;
