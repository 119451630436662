import React, { Component } from "react";
import { observer } from "mobx-react";

import { SimpleCard } from "../../Card";
import { GridContainer, GridItem } from "../../Grid";

import DatePicker from "../../Form/DatePicker";

// Utils
import {
  momentDateFromUnixDate,
  startOfTheDayMoment,
  endOfTheDayMoment,
  addDaysToDate,
} from "../../../utils/DateUtils";

interface DateConfigDateInOutProps {
  dayIn: string;
  dayOut: string;
  onChangeDateIn: (dayIn: string) => void;
  onChangeDateOut: (dayOut: string) => void;
}

@observer
class DateConfigDateInOut extends Component<DateConfigDateInOutProps> {
  getMomentDayInOutDates() {
    const { dayIn, dayOut } = this.props;
    let startDate = dayIn
      ? momentDateFromUnixDate(dayIn)
      : startOfTheDayMoment();
    const endDate = dayOut
      ? momentDateFromUnixDate(dayOut)
      : addDaysToDate(endOfTheDayMoment(), 7);
    return { startDate, endDate };
  }

  onChangeDate = (name: string, newValue: any) => {
    if (name === "dayIn") {
      this.props.onChangeDateIn(newValue.format("X"));
    } else {
      this.props.onChangeDateOut(newValue.format("X"));
    }
  };

  renderDatePicker = ({ name, label, value }) => (
    <GridItem xs={12} sm={6}>
      <SimpleCard cardStyle={{ marginTop: 0 }}>
        {/* @ts-ignore */}
        <DatePicker
          label={label}
          name={name}
          value={value}
          hideTime
          onChange={this.onChangeDate}
          fullWidth
          removeMargin
        />
      </SimpleCard>
    </GridItem>
  );

  render() {
    const { startDate, endDate } = this.getMomentDayInOutDates();

    return (
      <GridContainer>
        {this.renderDatePicker({
          name: "dayIn",
          label: "Data início",
          value: startDate,
        })}
        {this.renderDatePicker({
          name: "dayOut",
          label: "Data fim",
          value: endDate,
        })}
      </GridContainer>
    );
  }
}

export default DateConfigDateInOut;
