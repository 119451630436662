import PlaylistScheduleModel from "../../Model/PlaylistScheduleModel";

import Logger from "simplyas-js-logger";
import AuthStore from "../AuthStore/AuthStore";
import InternalEventsStore from "../InternalEventsStore/InternalEventsStore";
import MediaLibraryStore from "../MediaLibraryStore/MediaLibraryStore";
import NotificationsStore from "../NotificationsStore/NotificationsStore";
import PlaylistsStore from "../PlaylistsStore/PlaylistsStore";
import SettingsStore from "../SettingsStore/SettingsStore";
import PlaylistScheduleStore from "../PlaylistScheduleStore/PlaylistScheduleStore";
import ContractStore from "../ContractStore/ContractStore";

class MainStore {
  authStore: AuthStore;
  contractStore: ContractStore;
  settingsStore: SettingsStore;
  internalEventsStore: InternalEventsStore;
  mediaLibraryStore: MediaLibraryStore;
  playlistsStore: PlaylistsStore;
  notificationsStore: NotificationsStore;
  playlistScheduleStore: PlaylistScheduleStore;

  constructor(services) {
    this.settingsStore = new SettingsStore();
    this.internalEventsStore = new InternalEventsStore();
    this.notificationsStore = new NotificationsStore();

    this.authStore = new AuthStore(
      this.notificationsStore,
      this.internalEventsStore,
      services.mobR2APIService
    );

    this.contractStore = new ContractStore(services.mobR2APIService);

    this.playlistsStore = new PlaylistsStore(
      this.internalEventsStore,
      this.notificationsStore,
      services.mobR2APIService,
      services.fileService
    );

    this.mediaLibraryStore = new MediaLibraryStore(
      this.internalEventsStore,
      this.notificationsStore,
      services.mobR2APIService,
      services.fileService
    );

    this.playlistScheduleStore = new PlaylistScheduleStore(
      this.internalEventsStore,
      {
        request: async (token) => {
          try {
            const response: any = await services.mobR2APIService.requestPlaylistSchedules(
              token
            );
            if (response.status === 200) {
              return response.data.map((companyData) =>
                PlaylistScheduleModel.fromJSON(companyData)
              );
            }
          } catch (error) {
            Logger.error("requestPlaylistSchedules error", error);
          }
          return [];
        },
        submit: async (data) => {
          try {
            await services.mobR2APIService.submitPlaylistSchedule(data);
          } catch (error) {
            Logger.error("submitPlaylistSchedule error", error);
          }
        },
        update: async (data) => {
          try {
            await services.mobR2APIService.updatePlaylistSchedule(data);
          } catch (error) {
            Logger.error("updatePlaylistSchedule error", error);
          }
        },
        delete: async (token) => {
          try {
            await services.mobR2APIService.deletePlaylistSchedule(token);
          } catch (error) {
            Logger.error("deletePlaylistSchedule error", error);
          }
        },
        reorder: async (orderedList) => {
          try {
            await services.mobR2APIService.reorderPlaylistSchedules(
              orderedList
            );
          } catch (error) {
            Logger.error("reorderPlaylistSchedules error", error);
          }
        },
        getCompanyName: (token) => this.contractStore.getWithToken(token)?.name,
        getPlaylistName: (token) =>
          this.playlistsStore.getWithToken(token)?.name,
      }
    );

    // set stores to services who need it
    services.mobR2APIService.setStores(this.authStore, this.notificationsStore);
    services.fileService.setStores(this.settingsStore);
  }
}

export default MainStore;
