import MobR2APIService from "./MobR2APIService";
import FileService from "./FileService";
import KeyboardEventsService from "./KeyboardEventsService";

class MainServices {
  mobR2APIService: MobR2APIService;
  fileService: FileService;
  keyboardEventsService: KeyboardEventsService;

  constructor() {
    this.mobR2APIService = new MobR2APIService();
    this.fileService = new FileService();
    this.keyboardEventsService = new KeyboardEventsService();
  }
}

export default MainServices;
