import React, { Component, CSSProperties, ReactElement } from "react";
import { Draggable } from "react-beautiful-dnd";

const getItemStyle = (draggableStyle: CSSProperties): CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: `0 0 ${8}px 0`,

  // styles we need to apply on draggable
  ...draggableStyle,
});

interface IDragAndDropItemProps {
  index: number;
  contextId: string;
  children(isDragging: boolean): ReactElement;
}

class DragAndDropItem extends Component<IDragAndDropItemProps> {
  render() {
    const { index, contextId } = this.props;

    return (
      <Draggable draggableId={`${contextId}_${index}`} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(provided.draggableProps.style)}
          >
            {this.props.children(snapshot.isDragging)}
          </div>
        )}
      </Draggable>
    );
  }
}

export default DragAndDropItem;
